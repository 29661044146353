import { getTemplatesFromTasks } from "@/features/intro_templates/lib/api"
import { IExtendedTemplate, ITemplate } from "@/features/templates/types"
import { CacheProvider } from "@/shared/cache/cacheProvider"
import { cloneDeep, isString, sortBy, uniqBy } from "lodash"
import { injectable } from "tsyringe"
import { TaskTemplateListView } from "../models/taskTemplateListView"
import { TaskRepository } from "../repositories/taskRepository"
import { ITask } from "../types/itask"
import { TaskSidebarModel } from "../models/taskSideBarModel"
import { TemplateType } from "../constants/templateType"
import { TaskStatus } from "../constants/taskStatus"
import { getService } from "hooks/useStartup"
import { FetchClient } from "@/core/client/fetch_client"
import { SupplierOpportunity } from "../supplier/models/args/supplierOpportunity"
import { ResponseModel } from "@/core/generated/api/models/response_model"

@injectable()
export class TaskProvider {
  private _cacheProvider: CacheProvider
  private _repository: TaskRepository
  private _selectedTemplateId: string = ""

  constructor(cacheProvider: CacheProvider, repository: TaskRepository) {
    this._cacheProvider = cacheProvider
    this._repository = repository
  }

  _getTaskAllCacheKey(
    state: string,
    templateId: string = "",
    templateType: string = "",
  ): string {
    return `task-all-${state}-${templateId}-${templateType}`
  }
  _getTasksCacheKey(state: string, skip: number, num: number): string {
    return `tasks-${state}-${skip}-${num}`
  }
  _getTemplateCacheKey(templateId: string): string {
    return `task-template-${templateId}`
  }
  _getTemplatesCacheKey(
    state: string,
    templateId: string,
    templateType: string,
  ): string {
    return `task-templates-${state}-${templateId}-${templateType}`
  }
  _getTemplatesBySpaceCacheKey(
    spaceId: string,
    state: string,
    templateId: string,
    templateType: string,
  ): string {
    return `task-templates-${spaceId}-${state}-${templateId}-${templateType}`
  }
  _getTemplateListViewBySpaceCacheKey(spaceId: string, state: string): string {
    return `task-template-list-view-${spaceId}-${state}`
  }
  _getByOwnerCacheKey(state: string): string {
    return `tasks-by-owner-${state}`
  }
  _getBySharedCacheKey(state: string): string {
    return `tasks-by-shared-${state}`
  }
  _getMinibidsCacheKey(state: string): string {
    return `task-minibids-${state}`
  }

  setSelectedTemplate(templateId: string) {
    this._selectedTemplateId = templateId
  }

  async getTasksAll(
    state: string,
    templateId: string,
    templateType: string,
  ): Promise<ITask[]> {
    const cacheKey: string = this._getTaskAllCacheKey(
      state,
      templateId,
      templateType,
    )
    let tasks: ITask[] = await this._cacheProvider.get(cacheKey)
    if (tasks !== undefined) {
      return tasks
    }

    //    tasks = await this._repository.getTasksAll(state, templateId, templateType)
    tasks = await this._repository.getTasks(
      state,
      templateId,
      templateType,
      0,
      10000,
    )
    await this._cacheProvider.add(cacheKey, tasks)

    return tasks
  }

  async _getTasksAllFromCache(
    state: string,
    templateId: string = "",
    templateType: string = "",
  ): Promise<ITask[]> {
    const cacheKey: string = this._getTaskAllCacheKey(
      state,
      templateId,
      templateType,
    )
    let tasks: ITask[] = await this._cacheProvider.get(cacheKey)
    if (tasks !== undefined) {
      return tasks
    }
    return []
  }

  async getTasks(
    state: string,
    templateId: string,
    templateType: string,
    skip: number,
    num: number,
  ): Promise<ITask[]> {
    const cacheKey: string = this._getTasksCacheKey(state, skip, num)
    let tasks: ITask[] = await this._cacheProvider.get(cacheKey)
    if (tasks !== undefined) {
      return tasks
    }

    tasks = await this._repository.getTasks(
      state,
      templateId,
      templateType,
      skip,
      num,
    )
    await this._cacheProvider.add(cacheKey, tasks)

    return tasks
  }

  async _getTaskFromCache(
    taskId: string,
    state: string,
  ): Promise<ITask | null> {
    const tasks: ITask[] = await this._getTasksAllFromCache(state)
    if (tasks === undefined) {
      return null
    }
    const taskIdx: number = tasks.findIndex((t) => t.uid == taskId)

    if (taskIdx === -1) {
      return null
    }

    const task: ITask = tasks[taskIdx]
    return task
  }

  async updateTaskState(task: ITask, fromState: string, toState: string) {
    let tasksFrom: ITask[] = await this._getTasksAllFromCache(fromState)
    let tasksTo: ITask[] = await this._getTasksAllFromCache(toState)

    let taskCached: ITask | null = null
    if (tasksFrom.length > 0) {
      const taskFromIdx: number = tasksFrom.findIndex((t) => t.uid == task.uid)
      if (taskFromIdx !== -1) {
        const cacheKeyFrom: string = this._getTaskAllCacheKey(fromState)
        taskCached = tasksFrom[taskFromIdx]
        taskCached._state = toState
        tasksFrom.splice(taskFromIdx, 1)
        await this._cacheProvider.add(cacheKeyFrom, tasksFrom)
      }
    }

    if (tasksTo.length > 0) {
      if (taskCached !== null) {
        tasksTo.push(taskCached)
      } else {
        const taskToIdx: number = tasksTo.findIndex((t) => t.uid == task.uid)
        if (taskToIdx !== -1) {
          taskCached = tasksTo[taskToIdx]
          taskCached._state = toState
        } else {
          taskCached = cloneDeep(task)
          taskCached._state = toState
          tasksTo.push(taskCached)
        }
      }

      const cacheKeyTo: string = this._getTaskAllCacheKey(toState)
      await this._cacheProvider.add(cacheKeyTo, tasksTo)
    }
  }

  async toggleInterest(
    taskId: string,
    isInterested: boolean,
    spaceUserId: string,
    feedback?: { reason: string; feedback?: string },
  ): Promise<void> {
    const result: boolean = await this._repository.toggleInterest(
      taskId,
      isInterested,
      feedback,
    )
    if (result) {
      let task: ITask | null = await this._getTaskFromCache(
        taskId,
        TaskStatus.Published,
      )
      if (task !== null) {
        if (task._interested_state === undefined) {
          task._interested_state = {}
        }
        task._interested_state[spaceUserId] = isInterested
        await this._updateTaskCache(task, TaskStatus.Published)
      }
    }
  }

  async deleteTask(taskId: string): Promise<void> {
    // TODO: remove from cache
  }

  async _updateTaskCache(task: ITask, state: string) {
    let tasks: ITask[] = await this._getTasksAllFromCache(state)
    if (tasks.length > 0) {
      const taskIdx: number = tasks.findIndex((t) => t.uid == task.uid)
      if (taskIdx !== -1) {
        const cacheKey: string = this._getTaskAllCacheKey(state)
        tasks[taskIdx] = task
        await this._cacheProvider.add(cacheKey, tasks)
      }
    }
  }

  async getTemplates(
    state: string,
    templateId: string,
    templateType: string,
  ): Promise<any> {
    const cacheKey: string = this._getTemplatesCacheKey(
      state,
      templateId,
      templateType,
    )
    let templates = await this._cacheProvider.get(cacheKey)
    if (templates !== undefined) {
      return templates
    }

    const tasks: ITask[] = await this.getTasksAll(
      state,
      templateId,
      templateType,
    )
    templates = await getTemplatesFromTasks(tasks)
    await this._cacheProvider.add(cacheKey, templates)

    return templates
  }

  async getExtendedTemplate(
    templateId: string,
  ): Promise<IExtendedTemplate | null> {
    if (templateId === "") return null

    const cacheKey: string = this._getTemplateCacheKey(templateId)
    let template: IExtendedTemplate = await this._cacheProvider.get(cacheKey)
    if (template !== undefined) {
      return template
    }

    template = await this._repository.getExtendedTemplate(templateId)
    if (template) {
      await this._cacheProvider.add(cacheKey, template)
    }

    return template
  }
  /*
  async getTableTemplate(
    templateId: string,
    spaceId: string,
    state: string,
  ): Promise<any> {
    const templates: any[] = await this.getTemplatesBySpace(spaceId, state)
    const template = templates.find((t) => t.uid === templateId)
    return template
  }
*/
  _existTemplate(templateId: string, templates: any[]): boolean {
    const template = templates.find((t) => t.uid === templateId)
    return template ? true : false
  }

  async getTemplatesBySpace(
    spaceId,
    state: string,
    templateId: string,
    templateType: string,
  ): Promise<any> {
    if (spaceId === undefined) return []

    const cacheKey: string = this._getTemplatesBySpaceCacheKey(
      spaceId,
      state,
      templateId,
      templateType,
    )
    let templates: any[] = await this._cacheProvider.get(cacheKey)
    if (templates !== undefined) {
      if (this._existTemplate(this._selectedTemplateId, templates) == false) {
        const extTemplate: IExtendedTemplate = await this.getExtendedTemplate(
          this._selectedTemplateId,
        )
        if (extTemplate) {
          templates.push(extTemplate)
          await this._cacheProvider.add(cacheKey, templates)
        }
      }

      return templates
    }

    const tasks: ITask[] = await this.getTasksAll(
      state,
      templateId,
      templateType,
    )
    templates = await getTemplatesFromTasks(tasks)
    //    templates = templates?.filter(
    //      (template) =>
    //        template.is_minibid === false && template._owner.uid === spaceId,
    //    )
    //    templates = templates?.filter((template) => template._owner.uid === spaceId)

    await this._cacheProvider.add(cacheKey, templates)

    return templates
  }
  /*
  async getTemplateListViewBySpace(
    spaceId,
    state: string,
  ): Promise<TaskTemplateListView[]> {
    if (spaceId === undefined) return []
    const cacheKey: string = this._getTemplateListViewBySpaceCacheKey(
      spaceId,
      state,
    )
    let templates: TaskTemplateListView[] =
      await this._cacheProvider.get(cacheKey)
    if (templates !== undefined) {
      return templates
    }

    templates = await this._repository.getSidebarTemplates()
    await this._cacheProvider.add(cacheKey, templates)

    return templates
*/
  async getTemplateListViewBySpace(
    spaceId: string,
    state: string,
  ): Promise<TaskSidebarModel> {
    if (spaceId === undefined) return undefined
    const cacheKey: string = this._getTemplateListViewBySpaceCacheKey(
      spaceId,
      state,
    )
    let templates: TaskSidebarModel = await this._cacheProvider.get(cacheKey)
    if (templates !== undefined) {
      return templates
    }

    templates = await this._repository.getSidebarTemplates()
    if (templates !== null) {
      await this._cacheProvider.add(cacheKey, templates)
    }

    return templates

    /*
    let let templatesBySpaceBySpace = await this.getTemplatesBySpace(spaceId, state)
    templatesBySpaceBySpace = templatesBySpaceBySpace?.filter(
      (template) =>
        template.is_minibid === false && template._owner.uid === spaceId,
    )

    let items: TaskTemplateListView[] = []
    for (let i = 0; i < templatesBySpace.length; i++) {
      const template = templatesBySpace[i]
    for (let i = 0; i < templatesBySpace.length; i++) {
      const template = templatesBySpace[i]
      let num: number = 0
      if (template.tasks !== undefined) {
        num = template.tasks.filter(
          (task: any) => task._state === "published" && task._shared === false,
        ).length
      }
      const item = new TaskTemplateListView(template.uid, template.name, num)
      items.push(item)
    }

    await this._cacheProvider.add(cacheKey, items)

    return items
*/
  }

  private _getTemplateType(model: TaskSidebarModel): string {
    if (model.externals.length > 0) {
      return TemplateType.External
    }
    if (model.sharedBy.length > 0) {
      return TemplateType.Shared
    }
    if (model.internals.length > 0) {
      return TemplateType.Internal
    }

    return ""
  }

  private _getTemplateId(
    templateType: string,
    model: TaskSidebarModel,
  ): string {
    if (templateType === TemplateType.External) {
      return model.externals[0].templateId
    }
    if (templateType === TemplateType.Shared) {
      return model.sharedBy[0].templateId
    }
    if (templateType === TemplateType.Internal) {
      return model.internals[0].templateId
    }

    return ""
  }

  async getDefaultTemplateTypeAndId(
    spaceId: string,
  ): Promise<[string, string]> {
    let defaultTemplateId: string = ""
    let defaultTemplateType: string = ""
    if (spaceId === undefined) {
      return [defaultTemplateId, defaultTemplateType]
    }

    const templates: TaskSidebarModel = await this.getTemplateListViewBySpace(
      spaceId,
      TaskStatus.Published,
    )
    //    console.log(
    //      `TaskSidebarModel: ${JSON.stringify(templates)}, spaceId: ${spaceId} `,
    //    )

    defaultTemplateType = this._getTemplateType(templates)
    defaultTemplateId = this._getTemplateId(defaultTemplateType, templates)

    return [defaultTemplateId, defaultTemplateType]
  }

  async getByOwner(
    state: string,
    templateId: string,
    templateType: string,
  ): Promise<any> {
    const cacheKey: string = this._getByOwnerCacheKey(state)
    let tasksByOwner = await this._cacheProvider.get(cacheKey)
    if (tasksByOwner !== undefined) {
      return tasksByOwner
    }

    const tasks = await this.getTasksAll(state, templateId, templateType)
    tasksByOwner = this._groupByOwner(
      tasks?.filter((task) => task?.is_minibid === true),
    )
    await this._cacheProvider.add(cacheKey, tasksByOwner)

    return tasksByOwner
  }

  _groupByOwner(data: any) {
    const groupedData = new Map()

    data?.forEach((item: any) => {
      const ownerUid = item?._owner?.uid
      const ownerName = item?._owner?.name
      const ownerIcon = item?._owner?.icon

      if (!!ownerUid && !groupedData.has(ownerUid)) {
        groupedData.set(ownerUid, {
          ownerUid,
          ownerName,
          ownerIcon,
          tasks: [],
        })
      }

      groupedData.get(ownerUid)?.tasks.push({ ...item })
    })
    return Array.from(groupedData.values())
  }

  _groupByTemplate = (data: any[]) => {
    const groupedData = new Map()

    data?.forEach((item) => {
      const templateUid = item.template.uid
      const templateName = item.template.name
      const template = item.template
      const ownerUid = item?._owner?.uid
      const ownerName = item?._owner?.name
      const ownerIcon = item?._owner?.icon

      if (!groupedData.has(templateUid)) {
        groupedData.set(templateUid, {
          ownerUid,
          ownerName,
          ownerIcon,
          templateUid,
          templateName,
          template,
          tasks: [],
        })
      }

      groupedData.get(templateUid).tasks.push({ ...item })
    })
    return Array.from(groupedData.values())
  }

  async getByShared(
    state: string,
    templateId: string,
    templateType: string,
  ): Promise<any> {
    const cacheKey: string = this._getBySharedCacheKey(state)
    let tasksByShared = await this._cacheProvider.get(cacheKey)
    if (tasksByShared !== undefined) {
      return tasksByShared
    }

    const tasks = await this.getTasksAll(state, templateId, templateType)
    const memoByTemplate = this._groupByTemplate(
      tasks?.filter((task) => !task?.is_minibid && task?._shared),
    )

    tasksByShared = this._groupTemplateByOwner(memoByTemplate)
    await this._cacheProvider.add(cacheKey, tasksByShared)

    return tasksByShared
  }

  _groupTemplateByOwner(data: any) {
    const groupedData = new Map()

    data.forEach((item: any) => {
      const ownerUid = item.ownerUid
      const ownerName = item.ownerName
      const ownerIcon = item.ownerIcon

      if (!groupedData.has(ownerUid)) {
        if (item.uid == "5c3afa74-1913-4a98-9132-d3acbb99c226") {
          console.log("no access")
        }
        if (item.uid == "9ca472fc-92a0-4e35-aa64-723367a401c3") {
          console.log("access")
        }

        groupedData.set(ownerUid, {
          ownerUid,
          ownerName,
          ownerIcon,
          templates: [],
        })
      }

      groupedData.get(ownerUid).templates.push({ ...item })
    })

    return Array.from(groupedData.values())
  }

  async getMinibids(
    state: string,
    templateId: string,
    templateType: string,
  ): Promise<ITask[]> {
    const cacheKey: string = this._getMinibidsCacheKey(state)
    let minibidTasks: ITask[] = await this._cacheProvider.get(cacheKey)
    if (minibidTasks !== undefined) {
      return minibidTasks
    }

    const tasks = await this.getTasksAll(state, templateId, templateType)
    minibidTasks = tasks?.filter((task) => task.is_minibid === true)
    await this._cacheProvider.add(cacheKey, minibidTasks)

    return minibidTasks
  }

  _search(searchValue, value = ""): boolean {
    const res: boolean = value
      .toLowerCase()
      .replace(/\s+/g, "")
      .includes(searchValue.toLowerCase().replace(/\s+/g, ""))

    return res
  }

  _isTaskOverdue(task: Record<string, any>, template: any): boolean {
    const isInteresting = task?.interested === true || task?._interested
    const hasCandidates = task?.candidates?.length > 0
    const isOverdue = new Date(task[template.deadlineField]) < new Date()
    if (isOverdue && !isInteresting && !hasCandidates) {
      return true
    }
    return false
  }

  async getFiltered(
    state: string,
    templateId: string,
    templateType: string,
    filterTemplate: any,
    searchValue: string,
    showOverdue: boolean,
  ): Promise<ITask[]> {
    const tasks: ITask[] = await this.getTasksAll(
      state,
      templateId,
      templateType,
    )
    const templateTasks = tasks.reduce((result, task) => {
      const taskTemplate: string | ITemplate = task.template
      const taskTemplateId: string = this.parseTemplateId(taskTemplate)

      if (templateId === "" || templateId === taskTemplateId) {
        result.push(task)
      }
      return result
    }, [])

    const filteredTasks = templateTasks.reduce((result, task) => {
      ;(searchValue.trim() === "" ||
        this._search(
          searchValue,
          typeof task?._name === "string" ? task._name : "",
        )) &&
        (filterTemplate?.is_minibid
          ? showOverdue
            ? this._isTaskOverdue(task, filterTemplate)
            : !this._isTaskOverdue(task, filterTemplate)
          : true) &&
        result.push(task)

      return result
    }, [])

    return filteredTasks
  }

  private parseTemplateId(taskTemplate: string | ITemplate): string {
    if (taskTemplate === undefined) {
      return ""
    }

    let taskTemplateId: string = isString(taskTemplate)
      ? (taskTemplate as string)
      : (taskTemplate as ITemplate).uid
    if (taskTemplateId === undefined) {
      return ""
    }

    return taskTemplateId
  }

  async getUniqueTemplates(
    state: string,
    templateId: string,
    templateType: string,
    filters,
  ): Promise<[ITask[], any]> {
    const templates: any[] = await this.getTemplates(
      state,
      templateId,
      templateType,
    )
    const uniques = uniqBy(templates, (t) => t?.uid)
    const uniqueTemplates = sortBy(uniques, (t) => t?.name)
    const filterTemplate =
      uniqueTemplates.find((t) => t?.uid === templateId) ?? uniqueTemplates[0]

    return [uniqueTemplates, filterTemplate]
  }
}
