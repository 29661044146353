import { v4 as uuid } from "uuid"

export class ResponseModel {
  data: Uint8Array
  code: number
  message: string
  iscompressed: boolean
  correlationid: string

  constructor(
    data: Uint8Array,
    code: number,
    message: string,
    iscompressed: boolean,
    correlationid: string = "",
  ) {
    this.data = data
    this.code = code
    this.message = message
    this.iscompressed = iscompressed
    this.correlationid = correlationid !== "" ? correlationid : uuid()
  }
}
