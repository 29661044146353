/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 5.27.3
 * source: protoResponseModel.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as pb_1 from "google-protobuf"
export class ProtoResponseModel extends pb_1.Message {
  _one_of_decls: number[][] = []
  constructor(
    data?:
      | any[]
      | {
          code?: number
          message?: string
          data?: string
          classes?: string[]
        },
  ) {
    super()
    pb_1.Message.initialize(
      this,
      Array.isArray(data) ? data : [],
      0,
      -1,
      [4],
      this._one_of_decls,
    )
    if (!Array.isArray(data) && typeof data == "object") {
      if ("code" in data && data.code != undefined) {
        this.code = data.code
      }
      if ("message" in data && data.message != undefined) {
        this.message = data.message
      }
      if ("data" in data && data.data != undefined) {
        this.data = data.data
      }
      if ("classes" in data && data.classes != undefined) {
        this.classes = data.classes
      }
    }
  }
  get code() {
    return pb_1.Message.getFieldWithDefault(this, 1, 0) as number
  }
  set code(value: number) {
    pb_1.Message.setField(this, 1, value)
  }
  get message() {
    return pb_1.Message.getFieldWithDefault(this, 2, "") as string
  }
  set message(value: string) {
    pb_1.Message.setField(this, 2, value)
  }
  get data() {
    return pb_1.Message.getFieldWithDefault(this, 3, "") as string
  }
  set data(value: string) {
    pb_1.Message.setField(this, 3, value)
  }
  get classes() {
    return pb_1.Message.getFieldWithDefault(this, 4, []) as string[]
  }
  set classes(value: string[]) {
    pb_1.Message.setField(this, 4, value)
  }
  static fromObject(data: {
    code?: number
    message?: string
    data?: string
    classes?: string[]
  }): ProtoResponseModel {
    const message = new ProtoResponseModel({})
    if (data.code != null) {
      message.code = data.code
    }
    if (data.message != null) {
      message.message = data.message
    }
    if (data.data != null) {
      message.data = data.data
    }
    if (data.classes != null) {
      message.classes = data.classes
    }
    return message
  }
  toObject() {
    const data: {
      code?: number
      message?: string
      data?: string
      classes?: string[]
    } = {}
    if (this.code != null) {
      data.code = this.code
    }
    if (this.message != null) {
      data.message = this.message
    }
    if (this.data != null) {
      data.data = this.data
    }
    if (this.classes != null) {
      data.classes = this.classes
    }
    return data
  }
  serialize(): Uint8Array
  serialize(w: pb_1.BinaryWriter): void
  serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
    const writer = w || new pb_1.BinaryWriter()
    if (this.code != 0) writer.writeInt32(1, this.code)
    if (this.message.length) writer.writeString(2, this.message)
    if (this.data.length) writer.writeString(3, this.data)
    if (this.classes.length) writer.writeRepeatedString(4, this.classes)
    if (!w) return writer.getResultBuffer()
  }
  static deserialize(
    bytes: Uint8Array | pb_1.BinaryReader,
  ): ProtoResponseModel {
    const reader =
        bytes instanceof pb_1.BinaryReader
          ? bytes
          : new pb_1.BinaryReader(bytes),
      message = new ProtoResponseModel()
    while (reader.nextField()) {
      if (reader.isEndGroup()) break
      switch (reader.getFieldNumber()) {
        case 1:
          message.code = reader.readInt32()
          break
        case 2:
          message.message = reader.readString()
          break
        case 3:
          message.data = reader.readString()
          break
        case 4:
          pb_1.Message.addToRepeatedField(message, 4, reader.readString())
          break
        default:
          reader.skipField()
      }
    }
    return message
  }
  serializeBinary(): Uint8Array {
    return this.serialize()
  }
  static deserializeBinary(bytes: Uint8Array): ProtoResponseModel {
    return ProtoResponseModel.deserialize(bytes)
  }
}
