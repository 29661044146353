import { Serializable } from "@/core/api/decorators/serializable_decorator"

@Serializable
export class OpportunityListView {
  uid: string = ""
  name: string = ""
  departmentId: string = ""
  departmentName: string = ""
  caseNumber: string = ""
  startDate: string = ""
  endDate: string = ""
  startEndDate: any = ""
  internalDeadline: string = ""
  templateId: string = ""
  ownerId: string = ""
  ownerName: string = ""
  customerId: string = ""
  customerName: string = ""

  constructor(
    data: {
      uid: string
      name: string
      departmentId: string
      departmentName: string
      caseNumber: string
      startDate: string
      endDate: string
      startEndDate: any
      internalDeadline: string
      templateId: string
      ownerId: string
      ownerName: string
      customerId: string
      customerName: string
    } | null = null,
  ) {
    if (data) {
      this.uid = data.uid
      this.name = data.name
      this.departmentId = data.departmentId
      this.departmentName = data.departmentName
      this.caseNumber = data.caseNumber
      this.startDate = data.startDate
      this.endDate = data.endDate
      this.startEndDate = data.startEndDate
      this.internalDeadline = data.internalDeadline
      this.templateId = data.templateId
      this.ownerId = data.ownerId
      this.ownerName = data.ownerName
      this.customerId = data.customerId
      this.customerName = data.customerName
    }
  }
}
