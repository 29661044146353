import { fetcher } from "@/utils/api"
import { useInfiniteQuery } from "@tanstack/react-query"
import { NotificationCategory } from "../types/notificationCategory"
import { TNotificationResponse } from "../types/notificationResponse"
import { NotificationScope } from "../types/notificationScope"

export const useNotifications = (
  scope: NotificationScope,
  category: NotificationCategory,
) => {
  const limit = 20

  const { data, ...rest } = useInfiniteQuery<TNotificationResponse>({
    queryKey: ["notifications", { scope, category }],
    queryFn: async ({ pageParam: skip }): Promise<TNotificationResponse> => {
      const url: string = `/notifications/v2?skip=${skip}&limit=${limit}&scope=${scope}&category=${category}`
      const response: TNotificationResponse = await fetcher(url)
      return response
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      return lastPage.has_next ? lastPage.next_skip : undefined
    },
  })

  const notifications = data?.pages.flatMap((page) => page.data) ?? []

  return { notifications, ...rest }
}
