import { RESTClient } from "@/core/api/client/rest_client"
import { getToken } from "@/utils/api"
import { injectable } from "tsyringe"
import { FetchSettings } from "./fetch_settings"
import { ResponseModel } from "../generated/api/models/response_model"

@injectable()
export class FetchClient extends RESTClient {
  constructor(settings: FetchSettings) {
    super(settings)
  }

  /**
   * @deprecated - Use the TypeSafe methods (see below) takeing a generic which must be a class.
   * All the generic typed methods return the ResponseModel for better error handling and optimized payload transfers.
   */
  public async fetchAsync(
    url: string,
    options: RequestInit = {},
  ): Promise<any> {
    const token: string = getToken()
    return this.fetchWithRetry(token, url, options)
  }

  public async getWithParamsAsync<T>(
    url: string,
    request: T,
  ): Promise<ResponseModel> {
    const token: string = getToken()
    return this.getWithParams(token, url, request)
  }

  public async getAsync<T>(url: string): Promise<ResponseModel> {
    const token: string = getToken()
    return this.get(token, url)
  }

  /*
    Example on how to use POST request with a TypeSafe Class Model:
    const client: FetchClient = getService(FetchClient)
    const model = new SupplierOpportunity()
    model.num = 666

    const response: ResponseModel = await client.postAsync<SupplierOpportunity>(
      "/supplier/opportunities/toggleinterest",
      model,
    )
  */
  public async postAsync<T>(url: string, model: T): Promise<ResponseModel> {
    const token: string = getToken()
    return this.post<T>(token, url, model)
  }

  public async putAsync<T>(url: string, model: T): Promise<ResponseModel> {
    const token: string = getToken()
    return this.put<T>(token, url, model)
  }

  public async patchAsync<T>(url: string, model: T): Promise<ResponseModel> {
    const token: string = getToken()
    return this.patch<T>(token, url, model)
  }

  public async deleteAsync<T>(url: string, model: T): Promise<ResponseModel> {
    const token: string = getToken()
    return this.delete<T>(token, url, model)
  }
}
