import { fetcher } from "../../../utils/api"

export type ILink = {
  host: string
  destination: string
  authentication: any
  password_required?: boolean
  error_code?: string
  link_type?: string
  deleted_date?: string
}

export const fetchLink = async (
  code: string,
  password?: string,
): Promise<ILink> => {
  return await fetcher(`/links/${code}?password=${password}`)
}
