export const DATE_TYPES = {
  ALLOCATION_PERIOD: "ALLOCATION_PERIOD",
  HOLIDAY_PERIOD: "HOLIDAY_PERIOD",
  OTHER_PERIOD: "OTHER_PERIOD",
  PRICE_ADJUSTMENT: "PRICE_ADJUSTMENT",
} as const

export type DateType = (typeof DATE_TYPES)[keyof typeof DATE_TYPES]

export type IDate<T = Record<string, any>> = {
  key: string
  type: DateType
  start: string | null
  end: string | null
  data?: T
}

export type IAllocationPeriodData = {
  allocation: number
  status: "hired" | "potential" | "extension"
}
