import { Serializable } from "@/core/api/decorators/serializable_decorator"
import { TaskTemplateListView } from "./taskTemplateListView"

@Serializable
export class TaskSidebarModel {
  internals: TaskTemplateListView[] = []
  externals: TaskTemplateListView[] = []
  sharedBy: TaskTemplateListView[] = []

  getNumInternals(): number {
    const len: number = this.internals.length
    return len
  }

  getNumExternals(): number {
    const len: number = this.externals.length
    return len
  }

  getNumSharedBy(): number {
    const len: number = this.sharedBy.length
    return len
  }
}
