import { isString } from "lodash"
import { useEffect, useState } from "react"
import { SketchPicker } from "react-color"
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover"
import { Button } from "../ui/button"

interface IColorPickerInput {
  color?: string | { alpha: string; green: string; red: string; blue: string }
  onSelect?(
    color: string | { a: string; g: string; r: string; b: string },
  ): void
  button?({ open }: { open(state: boolean): void }): JSX.Element
  buttonText?: string
  format?: "hex" | "rgb" | "raw"
}

export const ColorPickerInput = ({
  color,
  onSelect,
  button,
  buttonText = "Select color",
  format,
}: IColorPickerInput) => {
  const [_color, setColor] = useState<string>()

  useEffect(() => {
    if (!isString(color) && color !== undefined) {
      color = `rgba(${color.red},${color.green},${color.blue},${color.alpha})`
    }
    setColor(color as string)
  }, [color])

  const handleSelect = (result: any) => {
    let color: string = ""

    const { rgb, hex } = result

    switch (format) {
      case "hex":
        color = hex
        break
      case "rgb":
        color = `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`
        break
      case "raw":
        color = rgb
        break
      default: {
        const { rgb } = result
        color = `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`
        break
      }
    }
    setColor(color)
  }

  const toggleOpen = (state: boolean) => {
    if (state === true) return

    if (_color === undefined) return

    onSelect?.(_color)
  }

  return (
    <Popover onOpenChange={toggleOpen}>
      <PopoverTrigger>
        {button ? (
          button({ open: (state = true) => toggleOpen(state) })
        ) : (
          <Button variant="outline">
            <div
              style={{ backgroundColor: _color }}
              className="mr-2 h-3 w-3 rounded-full border"
            />
            <span>{buttonText}</span>
          </Button>
        )}
      </PopoverTrigger>
      <PopoverContent side="right" className="w-fit bg-white p-0">
        <SketchPicker color={_color} onChange={handleSelect} />
      </PopoverContent>
    </Popover>
  )
}
