import { ImageWithFallback } from "@/utils/ImageWithFallback"
import { cn } from "@/utils/helpers"
import { Tooltip } from "antd"
import { isEqual } from "lodash"
import React, { useEffect, useState } from "react"

interface IAvatar {
  email?: string
  text?: string
  className?: string
  tooltip?: string | JSX.Element
  border?: boolean
  size?: number
  selected?: boolean
  onToggle?: () => void
  spacing_size?: number
}

export const Avatar = React.memo(
  ({
    email = "",
    text,
    className = "",
    tooltip = null,
    border = true,
    size = 44,
    selected,
    onToggle,
    spacing_size = 10,
  }: IAvatar) => {
    const initials = email.substring(0, 2)
    const [toggle, setToggle] = useState(selected)

    const toggleHandler = () => {
      if (!onToggle) return
      setToggle((prev) => !prev)
      onToggle?.()
    }

    useEffect(() => {
      setToggle(selected)
    }, [selected])

    const avatarStyle = cn(
      "relative flex rounded-full first:!ml-[-2px] group self-center overflow-hidden",
      className,
      onToggle && "cursor-pointer",
      border && "border-2 border-white",
      toggle && "border-2 !border-blue-500",
    )

    const textContent = (
      <p
        style={{ fontSize: size / 2.5 }}
        className="pointer-events-none m-auto grid h-full w-full select-none place-content-center bg-blue-500 font-bold capitalize text-white"
      >
        {text ?? initials.toLowerCase()}
      </p>
    )

    const content = (
      <div
        style={{ height: size, width: size, marginLeft: -spacing_size }}
        className={avatarStyle}
        onClick={toggleHandler}
      >
        {text ? (
          textContent
        ) : (
          <ImageWithFallback
            src={`https://cdn.flextribe.io/avatars/${email}`}
            objectFit="cover"
            className="h-10 w-full max-w-[124px] object-cover"
            fill
            alt=""
            fallback={textContent}
          />
        )}
      </div>
    )

    return tooltip !== null ? (
      <Tooltip overlayStyle={{ pointerEvents: "none" }} title={tooltip}>
        {content}
      </Tooltip>
    ) : (
      content
    )
  },
  (prev, next) => isEqual(prev, next),
)

Avatar.displayName = "Avatar"

export default Avatar
