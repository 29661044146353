import { atom, selector } from "recoil"
import { getPages } from "../api"

export const PagesAtom = atom({
  key: "PagesAtom",
  default: selector<any>({
    key: "PagesAtom/default",
    get: async (): Promise<any[]> => {
      return await getPages()
    },
  }),
})
