import { cn } from "@/utils/helpers"
import { faCalendar } from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  DatePicker,
  DatePickerProps,
  DateTimePicker,
  DateTimePickerProps,
} from "@mui/x-date-pickers"
import dayjs, { Dayjs } from "dayjs"

type IGenericDatePicker = (
  | DatePickerProps<Dayjs>
  | DateTimePickerProps<Dayjs>
) & {
  label?: string
  readOnly?: boolean
  showtime?: boolean
  onChange(date: dayjs.Dayjs | null): void
  error?: string
}

export const GenericDatePicker = ({
  label,
  value,
  onChange,
  className,
  readOnly = false,
  showtime,
  format,
  error,
  ...props
}: IGenericDatePicker) => {
  const onChangeHandler = (date: dayjs.Dayjs) => {
    if (dayjs(date).isValid()) {
      onChange?.(date)
    }
  }

  let _format = "DD/MM/YYYY" + (showtime ? " HH:mm" : "")
  if (typeof format === "string") {
    _format = format
  }

  const _props: DatePickerProps<Dayjs> | DateTimePickerProps<Dayjs> = {
    ...props,
    className: `w-full text-lg border-none`,
    value: value ? dayjs(value) : null,
    onChange: onChangeHandler,
    format: _format,
    ampm: false,
    slots: {
      openPickerIcon: () => (
        <FontAwesomeIcon
          className="px-px"
          icon={faCalendar}
          size="xs"
          color="#ccc"
        />
      ),
    },
    sx: {
      ".MuiInputBase-root": {
        height: 30,
        borderRadius: 2,
        backgroundColor: "white",
        border: "none",
      },
      ".MuiInputBase-input": {
        fontSize: 14,
        paddingTop: 0,
        paddingBottom: 0,
        border: "none",
        padding: 0,
        ":focus": {
          background: "white",
        },
        color: "#79879B",
      },
      ".MuiOutlinedInput-root": {
        outline: "none",
        ring: "none",
        border: "none",
      },
      ".MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  }

  return (
    <div
      className={cn(
        "border-custom-gray group relative flex min-h-[3rem] w-full flex-col gap-0.5 rounded-xl border bg-white px-4 py-3 ring-inset group-focus:ring-2",
        error && "border-red-500",
      )}
    >
      <span
        className={cn(
          "text-custom-gray-dark pointer-events-none bg-inherit text-xs font-light leading-none transition-all",
        )}
      >
        {label}
      </span>

      {readOnly ? (
        <p className="text-sm">
          {value ? dayjs(value).format(_format) : "No date selected!"}
        </p>
      ) : (
        <>
          {showtime ? (
            <DateTimePicker {...(_props as DateTimePickerProps<Dayjs>)} />
          ) : (
            <DatePicker {...(_props as DatePickerProps<Dayjs>)} />
          )}
        </>
      )}
    </div>
  )
}
