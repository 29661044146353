import { INotification } from "@/features/notifications/types"
import {
  getNotifications,
  getUnresolvedNotificationsCount,
} from "@/features/notifications/utils/api"
import { useQuery } from "@tanstack/react-query"
import { queryClient } from "pages/_app"

export const refetchNotifications = () =>
  queryClient.invalidateQueries({ queryKey: ["notifications"] })

// export const useNotifications = ({ limit }: { limit?: number } = {}) => {
//   const { data: notifications } = useQuery<INotification[]>({
//     queryKey: ["notifications", { limit }],
//     queryFn: () => getNotifications(limit),
//     placeholderData: [],
//   })
//   return notifications
// }

// export const useUnresolvedNotificationsCount = () => {
//   const { data: count } = useQuery<number>({
//     queryKey: ["notifications", "unresolved", "count"],
//     queryFn: () => getUnresolvedNotificationsCount(),
//     placeholderData: 0,
//   })
//   return count
// }

// TODO Remove these functions once new notifictions are stable

/**
 * @deprecated Use notifications from modules instead
 */
export const useNotifications = ({ limit }: { limit?: number } = {}) => {
  const {
    data: notifications,
    isLoading,
    error,
  } = useQuery<INotification[], Error>({
    queryKey: ["notifications", { limit }],
    queryFn: async () => {
      try {
        const data = await getNotifications(limit)
        return data
      } catch (error) {
        console.error("Failed to fetch notifications:", error)
        throw error
      }
    },
    placeholderData: [],
  })

  return { notifications, isLoading, error }
}

/**
 * @deprecated Use notifications from modules instead
 */
export const useUnresolvedNotificationsCount = () => {
  const {
    data: unresolvedNoticiationsCount = 0,
    isLoading,
    error,
  } = useQuery<number, Error>({
    queryKey: ["notifications", "unresolved", "count"],
    queryFn: async () => {
      try {
        return await getUnresolvedNotificationsCount()
      } catch (error) {
        console.error("Error fetching unresolved notifications count:", error)
        throw error
      }
    },
    placeholderData: 0,
  })

  return { unresolvedNoticiationsCount, isLoading, error }
}

/**
 * @deprecated Use notifications from modules instead
 */
export const setNotifications = (
  updater: (notifications: INotification[] | undefined) => INotification[],
) => {
  return queryClient.setQueryData<INotification[]>(["notifications"], updater)
}
