import { TagProps } from "core/components/Tag"
import { useTranslation } from "next-i18next"
import { TNotification } from "../types/notification"
import { NotificationTopics } from "../types/notificationTopic"

type TNotificationMetadata = {
  title: string | undefined
  tagLabel: string | undefined
  tagVariant: TagProps["variant"] | undefined
}

export const useNotificationMetadata = (
  notification: TNotification,
): TNotificationMetadata => {
  const { t } = useTranslation("notifications")
  switch (notification.topic) {
    case NotificationTopics.ADDED_TO_TASK_TEAM:
      return {
        title: t("topics.added_to_task_team.title", {
          name: `<strong>${notification.sender_name}</strong>`,
        }),
        tagLabel: undefined,
        tagVariant: undefined,
      }
    case NotificationTopics.NEW_MESSAGE:
      return {
        title: t("topics.new_message.title", {
          name: `<strong>${notification.sender_name}</strong>`,
        }),
        tagLabel: t("topics.new_message.label"),
        tagVariant: "neutral",
      }
    case NotificationTopics.CANDIDATE_NOMINATED:
      return {
        title: t("topics.talent_added.title", {
          candidate: `<strong>${notification.candidate?.firstname} ${notification.candidate?.lastname}</strong>`,
          name: `<strong>${notification.sender_name}</strong>`,
        }),
        tagLabel: t("topics.talent_added.label"),
        tagVariant: "positive",
      }
    case NotificationTopics.CANDIDATE_UPDATED:
      return {
        title: t("topics.talent_updated.title", {
          name: `<strong>${notification.sender_name}</strong>`,
        }),
        tagLabel: t("topics.talent_updated.label"),
        tagVariant: "neutral",
      }
    case NotificationTopics.CANDIDATE_OFFERED:
      return {
        title: t("topics.candidate_offered.title", {
          candidate: `<strong>${notification.candidate?.firstname} ${notification.candidate?.lastname}</strong>`,
        }),
        tagLabel: t("topics.candidate_offered.label"),
        tagVariant: "neutral",
      }
    case NotificationTopics.CANDIDATE_INTERVIEW:
      return {
        title: t("topics.candidate_interview.title", {
          candidate: `<strong>${notification.candidate?.firstname} ${notification.candidate?.lastname}</strong>`,
        }),
        tagLabel: t("topics.candidate_interview.label"),
        tagVariant: "neutral",
      }
    case NotificationTopics.CANDIDATE_HIRED: {
      return {
        title: t("topics.candidate_hired.title", {
          name: `<strong>${notification.sender_name}</strong>`,
          candidate: `<strong>${notification.candidate?.firstname} ${notification.candidate?.lastname}</strong>`,
        }),
        tagLabel: t("topics.candidate_hired.label"),
        tagVariant: "positive",
      }
    }
    case NotificationTopics.DEADLINE_REMINDER:
      return {
        title: t("topics.deadline_reminder.title"),
        tagLabel: t("topics.deadline_reminder.label"),
        tagVariant: "warning",
      }
    case NotificationTopics.DEADLINE_REMINDER_INTERESTED:
      return {
        title: t("topics.interested_in_task_reminder.title"),
        tagLabel: t("topics.interested_in_task_reminder.label"),
        tagVariant: "warning",
      }
    case NotificationTopics.DEADLINE_PASSED:
      return {
        title: t("topics.deadline_passed.title"),
        tagLabel: t("topics.deadline_passed.label"),
        tagVariant: "warning",
      }
    case NotificationTopics.INTERESTED_IN_TASK:
      return {
        title: t("topics.interested_in_task.title", {
          name: `<strong>${notification.sender_name}</strong>`,
        }),
        tagLabel: t("topics.interested_in_task.label"),
        tagVariant: "neutral",
      }

    case NotificationTopics.NOT_INTERESTED_IN_TASK:
      return {
        title: t("topics.not_interested_in_task.title", {
          name: `<strong>${notification.sender_name}</strong>`,
        }),
        tagLabel: t("topics.not_interested_in_task.label"),
        tagVariant: "negative",
      }
    case NotificationTopics.RFQ_SENT:
      return {
        title: t("topics.rfq_sent.title", {
          name: `<strong>${notification.sender_name}</strong>`,
        }),
        tagLabel: t("topics.rfq_sent.label"),
        tagVariant: "neutral",
      }
    case NotificationTopics.TASK_CANCELLED:
      return {
        title: t("topics.task_cancelled.title"),
        tagLabel: t("topics.task_cancelled.label"),
        tagVariant: "warning",
      }
    case NotificationTopics.REMOVED_FROM_TASK:
      return {
        title: t("topics.removed_from_task.title", {
          candidate: `<strong>${notification.candidate?.firstname} ${notification.candidate?.lastname}</strong>`,
        }),
        tagLabel: t("topics.removed_from_task.label"),
        tagVariant: "warning",
      }
    case NotificationTopics.REMOVED_FROM_TASK_SUITABLE:
      return {
        title: t("topics.removed_from_task_suitable.title", {
          candidate: `<strong>${notification.candidate?.firstname} ${notification.candidate?.lastname}</strong>`,
        }),
        tagLabel: t("topics.removed_from_task_suitable.label"),
        tagVariant: "warning",
      }
    case NotificationTopics.REMOVED_FROM_TASK_INTERVIEW:
      return {
        title: t("topics.removed_from_task_interview.title", {
          candidate: `<strong>${notification.candidate?.firstname} ${notification.candidate?.lastname}</strong>`,
        }),
        tagLabel: t("topics.removed_from_task_interview.label"),
        tagVariant: "warning",
      }
    case NotificationTopics.CONTRACT_REQUEST:
      return {
        title: t("topics.contract_request.title", {
          name: `<strong>${notification.sender_name}</strong>`,
        }),
        tagLabel: t("topics.contract_request.label"),
        tagVariant: "neutral",
      }
    case NotificationTopics.CONTRACT_APPROVED:
      return {
        title: t("topics.contract_approved.title", {
          name: `<strong>${notification.sender_name}</strong>`,
        }),
        tagLabel: t("topics.contract_approved.label"),
        tagVariant: "positive",
      }
    case NotificationTopics.CONTRACT_REJECTED:
      return {
        title: t("topics.contract_rejected.title", {
          name: `<strong>${notification.sender_name}</strong>`,
        }),
        tagLabel: t("topics.contract_rejected.label"),
        tagVariant: "negative",
      }
    case NotificationTopics.WON_BID:
      return {
        title: t("topics.won_bid.title", {
          candidate: `<strong>${notification.candidate?.firstname} ${notification.candidate?.lastname}</strong>`,
        }),
        tagLabel: t("topics.won_bid.label"),
        tagVariant: "positive",
      }
    case NotificationTopics.WITHDRAWN:
      return {
        title: t("topics.withdrawn.title", {
          candidate: `<strong>${notification.candidate?.firstname} ${notification.candidate?.lastname}</strong>`,
        }),
        tagLabel: t("topics.withdrawn.label"),
        tagVariant: "neutral",
      }

    default:
      return {
        title: undefined,
        tagLabel: undefined,
        tagVariant: undefined,
      }
  }
}
