import { faChevronRight } from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IFolderContent } from "../types"

export function Breadcrumbs({
  crumbs,
  onClick,
}: {
  crumbs: IFolderContent["parents"]
  onClick(uid: string): void
}) {
  if (crumbs?.length === 0) {
    return <></>
  }

  const getTime = (date: string) => {
    return new Date(date).getTime()
  }

  const sortedCrumbs = crumbs?.sort(
    (a, b) => getTime(a.created) - getTime(b.created),
  )

  return (
    <div className="flex">
      {sortedCrumbs?.map((crumb, i) => (
        <button
          onClick={() => onClick(crumb?.uid)}
          key={i}
          className="group flex items-center"
        >
          <p className="cursor-pointer text-xs font-medium group-hover:text-blue-500">{`${crumb?.name}`}</p>
          {i !== crumbs?.length - 1 && (
            <FontAwesomeIcon
              icon={faChevronRight}
              className="mx-2 text-xs group-hover:text-blue-500"
            />
          )}
        </button>
      ))}
    </div>
  )
}
