import {
  faEye,
  faEyeSlash,
  faTrash,
} from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { faEllipsisVertical } from "@awesome.me/kit-44b29310a6/icons/classic/solid"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "core/components/Button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "core/components/Menu"
import { useTranslation } from "next-i18next"
import { useMarkNotificationAsRead } from "../hooks/useMarkNotificationAsRead"
import { useMarkNotificationAsUnread } from "../hooks/useMarkNotificationAsUnread"
import { TNotification } from "../types/notification"
import { NotificationScope } from "../types/notificationScope"

type TNotificationActionButtonProps = {
  notification: TNotification
  deleteNotification: (uid: string) => void
}

export const NotificationActionButton = ({
  notification,
  deleteNotification,
}: TNotificationActionButtonProps) => {
  const { t } = useTranslation("notifications")
  const markNotificationAsRead = useMarkNotificationAsRead()
  const markNotificationAsUnread = useMarkNotificationAsUnread()

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button className="mt-1" variant="text" size="icon">
          <FontAwesomeIcon
            className="cursor-pointer"
            icon={faEllipsisVertical}
            size="lg"
          />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="z-[100]">
        {notification.is_read ? (
          <DropdownMenuItem
            onClick={(e) => {
              e.stopPropagation()
              markNotificationAsUnread(notification.uid)
            }}
            icon={faEyeSlash}
          >
            {t("actions.mark_as_unread")}
          </DropdownMenuItem>
        ) : (
          <DropdownMenuItem
            onClick={(e) => {
              e.stopPropagation()
              markNotificationAsRead(notification.uid)
            }}
            icon={faEye}
          >
            {t("actions.mark_as_read")}
          </DropdownMenuItem>
        )}
        {notification.scope === NotificationScope.PERSONAL && (
          <DropdownMenuItem
            onClick={(e) => {
              e.stopPropagation()
              deleteNotification(notification.uid)
            }}
            icon={faTrash}
          >
            {t("actions.delete_notification")}
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
