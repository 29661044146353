import { useEffect, useState } from "react"

type LocalStorage<T> = [T, React.Dispatch<React.SetStateAction<T>>]

export const useLocalStorage = <T>(
  key: string,
  defaultValue: T,
): LocalStorage<T> => {
  const [value, setValue] = useState(() => {
    let currentValue

    try {
      currentValue = JSON.parse(
        localStorage.getItem(key) || String(defaultValue),
      )
    } catch (error) {
      currentValue = defaultValue
    }

    return currentValue
  })

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value))
  }, [value, key])

  return [value, setValue]
}
