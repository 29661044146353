export class RESTSettings {
  domainUrl: string
  numRetries: number
  backoff: number

  constructor(
    domainUrl: string,
    numRetries: number = 3,
    backoff: number = 300,
  ) {
    this.domainUrl = domainUrl
    this.numRetries = numRetries
    this.backoff = backoff
  }
}
