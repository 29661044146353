import { useEffect, useState } from "react"
import { useRecoilValue } from "recoil"
import { meAtom } from "../../atoms/users"
import { getItem } from "../../utils/browserStorage"

export const SignedIn = ({ children }) => {
  const user = useRecoilValue(meAtom)

  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    const hasCookie = !!getItem("_ft_a_")
    if (hasCookie === true) {
      setIsLoggedIn(true)
    }
  }, [])

  useEffect(() => {
    setIsLoggedIn(!!user?.uid)
  }, [user])

  if (isLoggedIn) return children
  return null
}
