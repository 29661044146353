/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 5.27.3
 * source: protoRequestModel.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as pb_1 from "google-protobuf"
export class ProtoRequestModel extends pb_1.Message {
  _one_of_decls: number[][] = []
  constructor(
    data?:
      | any[]
      | {
          data?: string
          classes?: string[]
        },
  ) {
    super()
    pb_1.Message.initialize(
      this,
      Array.isArray(data) ? data : [],
      0,
      -1,
      [2],
      this._one_of_decls,
    )
    if (!Array.isArray(data) && typeof data == "object") {
      if ("data" in data && data.data != undefined) {
        this.data = data.data
      }
      if ("classes" in data && data.classes != undefined) {
        this.classes = data.classes
      }
    }
  }
  get data() {
    return pb_1.Message.getFieldWithDefault(this, 1, "") as string
  }
  set data(value: string) {
    pb_1.Message.setField(this, 1, value)
  }
  get classes() {
    return pb_1.Message.getFieldWithDefault(this, 2, []) as string[]
  }
  set classes(value: string[]) {
    pb_1.Message.setField(this, 2, value)
  }
  static fromObject(data: {
    data?: string
    classes?: string[]
  }): ProtoRequestModel {
    const message = new ProtoRequestModel({})
    if (data.data != null) {
      message.data = data.data
    }
    if (data.classes != null) {
      message.classes = data.classes
    }
    return message
  }
  toObject() {
    const data: {
      data?: string
      classes?: string[]
    } = {}
    if (this.data != null) {
      data.data = this.data
    }
    if (this.classes != null) {
      data.classes = this.classes
    }
    return data
  }
  serialize(): Uint8Array
  serialize(w: pb_1.BinaryWriter): void
  serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
    const writer = w || new pb_1.BinaryWriter()
    if (this.data.length) writer.writeString(1, this.data)
    if (this.classes.length) writer.writeRepeatedString(2, this.classes)
    if (!w) return writer.getResultBuffer()
  }
  static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProtoRequestModel {
    const reader =
        bytes instanceof pb_1.BinaryReader
          ? bytes
          : new pb_1.BinaryReader(bytes),
      message = new ProtoRequestModel()
    while (reader.nextField()) {
      if (reader.isEndGroup()) break
      switch (reader.getFieldNumber()) {
        case 1:
          message.data = reader.readString()
          break
        case 2:
          pb_1.Message.addToRepeatedField(message, 2, reader.readString())
          break
        default:
          reader.skipField()
      }
    }
    return message
  }
  serializeBinary(): Uint8Array {
    return this.serialize()
  }
  static deserializeBinary(bytes: Uint8Array): ProtoRequestModel {
    return ProtoRequestModel.deserialize(bytes)
  }
}
