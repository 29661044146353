import { DropdownItem } from "@/shared/components/items/DropdownItem"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { Menu, Transition } from "@headlessui/react"
import { Fragment } from "react"

interface IFileOptions {
  children: React.ReactElement
  options: Option[]
  disabled: boolean
}

type Option = {
  title: string
  icon: IconDefinition
  onClick(): void
  color?: string
}

export default function FileOptions({
  children,
  options,
  disabled,
}: IFileOptions) {
  return (
    <Menu as="div">
      <Menu.Button className="text-left">{children}</Menu.Button>
      {!disabled && (
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute z-50 mt-2 w-56 divide-y divide-gray-100 rounded-md bg-white shadow-xl">
            {options.map(({ color, ...props }, i) => (
              <Menu.Item key={i}>
                <DropdownItem className={color} {...props} selected={true} />
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      )}
    </Menu>
  )
}
