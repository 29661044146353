import { cn } from "@/utils/helpers"
import { useState } from "react"
import { MoneyInput } from "../inputs"

interface IPriceRange {
  readOnly?: boolean
  label?: string
  value: number[]
  onChange(prices: number[]): void
  error?: string
}

export const PriceRange = ({
  label,
  value = [0, 0],
  onChange,
  error,
}: IPriceRange) => {
  const [prices, setPrices] = useState<number[]>(value)

  const handleOnChange = (index: number) => (value: number) => {
    const clone = [...prices]
    clone[index] = value
    setPrices(clone)
    onChange(clone)
  }

  return (
    <div
      className={cn(
        "border-custom-gray group relative flex min-h-[3rem] w-full flex-col gap-1 rounded-xl border bg-white px-4 py-5 ring-inset group-focus:ring-2",
        error && "border-red-500",
      )}
    >
      <span
        className={cn(
          "text-custom-gray-dark pointer-events-none bg-inherit text-xs font-light leading-none transition-all",
        )}
      >
        {label}
      </span>
      <div className="flex items-center justify-between gap-2">
        <MoneyInput
          className="rounded-none border-x-0 border-b border-t-0"
          value={prices[0]}
          onChange={handleOnChange(0)}
        />

        <span className="bg-custom-gray-dark h-0.5 w-3">
          <p className="flex w-full flex-col flex-wrap text-xs font-medium text-gray-500 opacity-0">
            -
          </p>
        </span>
        <MoneyInput
          className="rounded-none border-x-0 border-b border-t-0"
          value={prices[1]}
          onChange={handleOnChange(1)}
        />
      </div>
    </div>
  )
}
