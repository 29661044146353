import { IApp } from "@/features/apps/types"
import {
  FieldClassifications,
  FieldType,
  IField,
} from "@/features/fields/types"
import { CLASSIFICATION_TYPES } from "@/features/fields/utils/constants"
import { IFile } from "@/features/storage/types"
import { ITag } from "@/features/tags/types"
import { CONTINUATION } from "@/shared/components/fields/ExtendedTask"
import { formatValue } from "@/shared/components/inputs"
import { DATE_TYPES, IDate } from "@/types/glossary"
import { TTaskUser } from "@/types/tasks/users"
import { faFile } from "@awesome.me/kit-44b29310a6/icons/classic/solid"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import clsx, { ClassValue } from "clsx"
import dayjs from "dayjs"
import { orderBy } from "lodash"
import { twMerge } from "tailwind-merge"
import { v4 as uuidv4 } from "uuid"
import { fetcher } from "./api"
import { getSpaceByUid } from "./api/spaces"
import { addAttachmentsToNode } from "./api/storage"
import { iconClasses } from "./constants"

export const getRandomArbitrary = (min: number, max: number): number => {
  return Math.random() * (max - min) + min
}

export const getRandomInt = (min: number, max: number): number => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const getRandomString = (length = 12, includeSymbols = true): string => {
  let result = ""
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  let symbols = ""
  if (includeSymbols) {
    symbols = `~!@#$%^&*()_-+={[}]|:;"'<,>.?/`
  }
  const charsLen = chars.length

  for (let i = 0; i < length; i++) {
    result += chars.concat(symbols)[Math.floor(Math.random() * charsLen)]
  }

  return result
}

export const keysToLower = (
  input: Record<string, any> | Array<any>,
): Record<string, any> | Array<any> => {
  if (typeof input !== "object" || input instanceof Date) {
    return input
  }

  let output = {}
  if (Array.isArray(input)) {
    output = []
    for (const i of input) {
      ;(output as Array<any>).push(keysToLower(i))
    }
    return output
  }

  for (const i in input) {
    let val = input[i]
    ;(output as Record<string, any>)[i.toLowerCase()] =
      typeof val === "object" && val !== null ? keysToLower(val) : val
  }

  return output
}

export const intersection = (
  arr1: Array<any>,
  arr2: Array<any>,
): Array<any> => {
  return arr1.filter((x) => arr2.includes(x))
}

export const difference = (arr1: Array<any>, arr2: Array<any>): Array<any> => {
  return arr1.filter((x) => !arr2.includes(x))
}

export const symDifference = (
  arr1: Array<any>,
  arr2: Array<any>,
): Array<any> => {
  return arr1
    .filter((x) => !arr2.includes(x))
    .concat(arr2.filter((x) => !arr1.includes(x)))
}

export const isEmptyObject = (obj: Record<string, any>) => {
  return (
    obj &&
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  )
}

export const isObject = (value: any): boolean => {
  return !!(value && typeof value === "object" && !Array.isArray(value))
}

export const capitalize = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export const currentTimestamp = (): number => {
  return Math.floor(new Date().getTime() / 1000)
}

export const getTimestamp = (date: Date): number => {
  return Math.floor(new Date(date).getTime() / 1000)
}

export const isInstanceOf = (
  object: Record<string, any>,
  instanceOf: string,
): boolean => {
  return object?.constructor?.name.toLowerCase() === instanceOf.toLowerCase()
}

export const getLocaleMonths = (
  localeName: string = "da-DK",
  monthFormat = "long" as Intl.DateTimeFormatOptions["month"],
) => {
  const format = new Intl.DateTimeFormat(localeName, { month: monthFormat })
    .format
  return [...Array(12)].map((_, m) => {
    return format(new Date(Date.UTC(2023, m)))
  })
}

export const moneyConversion = (amount) => {
  return amount * 100
}

export const moneyDeconversion = (amount) => {
  return amount / 100
}

export const dayjsLocaleMapper = {
  "da-DK": "da",
  "no-NO": "nb",
  "sv-SE": "sv",
  "en-US": "en",
}

export const reorderList = (
  list: any[],
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}
export function getFileConfig(mimeType: string): {
  bgColor: string
  color: string
  icon: IconDefinition
} {
  const fileConfig = Object.entries(iconClasses).find(([k]) =>
    mimeType?.startsWith(k),
  )
  return fileConfig
    ? fileConfig[1]
    : { bgColor: "bg-blue-100", color: "text-blue-500", icon: faFile }
}

export const parseName = (profile: any): string => {
  if (!profile) {
    return "No user available"
  }
  let name = profile.email
  if (profile?.firstname && profile?.lastname) {
    name = `${profile.firstname} ${profile.lastname}`
  }
  return name
}

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs))
}

export const compareStrings = (a: string, b: string): number => {
  const as: String = new String(a)
  const bs: String = new String(b)
  const res: number =
    as || bs ? (!as ? -1 : !bs ? 1 : as?.localeCompare(bs as string)) : 0
  return res
}

export const isEmail = (str: string): boolean => {
  return !!new String(str).match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  )
}

export const simpleDate = (date, hasTime = false) => {
  let format = "D MMM YYYY"
  if (hasTime === true) {
    format = `${format} HH:mm`
  }
  return dayjs(date).format(format)
}

export const isValidDate = (date: string): boolean => {
  return isNaN(new Date(date).getDay()) !== true
}

export const isValidISOString = (string: string) => {
  const isoPattern =
    /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(\.\d{1,9})?)?Z?$/
  return isoPattern.test(string)
}

export const fieldRegex = /{{(.*?)}}/g

export const parseFieldValue = async (field: IField, datasource: any) => {
  if (!field || !datasource) return ""
  const { type, key, showtime } = field
  const value = datasource[key]
  let parsedValue = ""

  if (!value) return parsedValue

  switch (type) {
    case "money": {
      parsedValue = formatValue(value)
      break
    }
    case "price_range": {
      const [first, second] = value
      parsedValue = `${first} - ${second}`
      break
    }
    case "select":
    case "multiselect": {
      const options = await fetcher(`/datasources/options?field=${field.uid}`)
      const uids = Array.isArray(value) ? value : [value]
      const selected = options.filter((opt) => uids.includes(opt.uid))
      parsedValue = selected.map((v) => v.text).join(", ")
      break
    }
    case "attachments" as FieldType: {
      parsedValue = value?.map((file: IFile) => file.filename).join(", ")
      break
    }
    case "progress_bar" as FieldType: {
      parsedValue = value?.status ?? ""
      break
    }
    case "suppliers" as FieldType: {
      parsedValue = value?.map?.((v) => v.name).join(", ")
      break
    }
    case "agreement_owner": {
      const uid = typeof value === "string" ? value : value?.uid
      const space = await getSpaceByUid(uid)
      parsedValue = space?.name ?? ""
      break
    }
    case "fte": {
      parsedValue = ((value ?? 0) / 37).toFixed(2)
      break
    }
    case "extended_task": {
      const continuationType =
        value?.continuation_type === CONTINUATION[CONTINUATION.INTERNAL]
          ? "Continuation of own consultant"
          : "Continuation competitor"
      parsedValue = continuationType
      break
    }
    case "cv_templates" as FieldType: {
      parsedValue = ""
      break
    }
    case "tags": {
      const tags = Object.values(value) as ITag[]
      parsedValue = tags.map((t) => t.name).join(", ")
      break
    }
    case "date": {
      const format = showtime ? "DD MMM YYYY HH:mm" : "DD MMM YYYY"
      parsedValue = value ? dayjs(value).format(format) : ""
      break
    }
    case "date_range": {
      const [firstDate, secondDate] = value
      parsedValue =
        dayjs(firstDate).format("DD MMM YYYY") +
        " - " +
        dayjs(secondDate).format("DD MMM YYYY")
      break
    }
    case "multi_date_range": {
      parsedValue = value.map(
        ([firstDate, secondDate]) =>
          dayjs(firstDate).format("DD MMM YYYY") +
          " - " +
          dayjs(secondDate).format("DD MMM YYYY"),
      )
      break
    }
    case "responsibles" as FieldType: {
      parsedValue = value.map((user) => parseName(user)).join(", ")
      break
    }
    case "customer" as FieldType: {
      parsedValue = value.name
      break
    }
    case "party": {
      parsedValue = value?.content?.replace(fieldRegex, (match, key) => {
        const party = datasource?.parties?.find((p) => p.user === value?.user)
        return party?.[key] ?? ""
      })
      break
    }
    default: {
      parsedValue = value
      break
    }
  }
  return parsedValue
}

export const sortFieldValues = (
  data: any[],
  key: string,
  type: string,
  direction: "asc" | "desc",
) => {
  if (!type) return data

  switch (type) {
    case "date": {
      const orderVal = direction === "desc" ? 0 : null
      return orderBy(
        data,
        (row) =>
          isValidDate(row[key]) ? new Date(row[key]).getTime() : orderVal,
        direction,
      )
    }
    case "date_range":
    case "price_range": {
      return orderBy(data, (row) => row[key]?.[0], direction)
    }
    case "select": {
      return orderBy(data, (row) => row[key]?.text, direction)
    }
    case "multi_date_range":
    case "tags":
    case "attachments":
    case "responsibles":
    case "multiselect":
    case "owners":
    case "contract_suppliers":
    case "talents":
    case "viewers": {
      return orderBy(data, (row) => row[key]?.length, direction)
    }
    case "progress_bar": {
      return orderBy(data, (row) => row[key].offer_status, direction)
    }
    case "agreement_owner": {
      return orderBy(data, (row) => row[key]?.name, direction)
    }
    case "fte": {
      return orderBy(data, (row) => (row[key] ?? 0) / 37, direction)
    }
    case "cv_templates":
    case "extended_task": {
      return orderBy(data, (row) => !!row[key], direction)
    }
    default: {
      return orderBy(data, key, direction)
    }
  }
}

export const getGlossaryDate = (
  date: string | string[] | string[][],
  end?: boolean,
): string => {
  const index = end ? 1 : 0
  if (Array.isArray(date?.[0])) {
    return date[0][index]
  }
  if (Array.isArray(date)) {
    return date[index] as string
  }
  return date
}

export const parseFieldDate = (
  date: string | string[] | string[][],
  type: string,
): string | string[] | string[][] => {
  if (Array.isArray(date?.[0])) {
    switch (type) {
      case "date": {
        return date[0][0]
      }
      case "date_range": {
        return date[0]
      }
      case "multi_date_range": {
        return date
      }
    }
  }
  if (Array.isArray(date)) {
    switch (type) {
      case "date": {
        return date[0]
      }
      case "date_range": {
        return date
      }
      case "multi_date_range": {
        return [date as string[]]
      }
    }
  }
  switch (type) {
    case "date": {
      return date
    }
    case "date_range": {
      return [date as string]
    }
    case "multi_date_range": {
      return [[date as string]]
    }
  }
  return ""
}

export const getFileKeys = (fields: IField[]) => {
  if (!Array.isArray(fields)) return []
  return fields
    .filter((f) => f.type === ("attachments" as FieldType))
    .map((f) => f.key)
}

export const extractFiles = (record = {}, fileKeys: string[]) => {
  return Object.entries(record).reduce(
    (acc, [key, value]) => {
      if (fileKeys?.includes(key)) {
        acc[0][key] = value
      } else {
        acc[1][key] = value
      }
      return acc
    },
    [{} as IFile[], {} as any],
  )
}

export const addFilesToNode = async (
  uid: string,
  files: Record<string, IFile[]>,
) => {
  await Promise.all(
    Object.entries(files).map(async ([key, files]) => {
      const fileUids = files.map((f) => f.uid)
      await addAttachmentsToNode(fileUids, uid, key)
    }),
  )
}

export const getAllocationDates = (dates: IDate[] | undefined) => {
  if (!dates) return []
  if (!Array.isArray(dates)) return []
  return dates.filter((d) => d.type === DATE_TYPES.ALLOCATION_PERIOD)
}

export const isWithinRange = (
  start: string,
  end: string,
  baseStart: Date,
  baseEnd: Date,
) => {
  const startDate = dayjs(start)
  const baseStartDate = dayjs(baseStart)
  const baseEndDate = dayjs(baseEnd)

  if (
    !startDate.isValid() ||
    !baseStartDate.isValid() ||
    !baseEndDate.isValid()
  )
    return false

  const isBeforeEnd = startDate.isBefore(baseEndDate)
  const isAfterStart =
    end === undefined ||
    end === null ||
    end === "" ||
    dayjs(end).isAfter(baseStartDate)
  return isBeforeEnd && isAfterStart
}

export const calculatePeriods = (task: {
  _start_date?: string
  _end_date?: string
  is_hired_on?: {
    this_task?: boolean
  }
  _allocation?: number
  _extensions?: string[][] | string[]
}) => {
  const start = getGlossaryDate(task?._start_date)
  const end = getGlossaryDate(task?._end_date, true)
  const isHired = task?.is_hired_on?.this_task
  const periods: IDate[] = []

  if (!!start && dayjs(start).isValid()) {
    periods.push({
      key: uuidv4(),
      type: DATE_TYPES.ALLOCATION_PERIOD,
      start,
      end,
      data: {
        status: isHired ? "hired" : "potential",
        allocation: task?._allocation,
        pricing_key: uuidv4(),
      },
    })
  }

  let extensions: string[][] = []
  if (!!task?._extensions) {
    if (Array.isArray(task?._extensions?.[0])) {
      extensions = task?._extensions as string[][]
    } else if (Array.isArray(task?._extensions)) {
      extensions = [task?._extensions as string[]]
    }
  }
  extensions?.map((dates: string[]) => {
    if (!dates?.length) return
    periods.push({
      key: uuidv4(),
      type: DATE_TYPES.ALLOCATION_PERIOD,
      start: dates?.[0],
      end: dates?.[1],
      data: {
        status: "extension",
        allocation: task?._allocation,
        pricing_key: uuidv4(),
      },
    })
  })
  periods.sort(
    (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime(),
  )
  return periods
}

export const getSubdomain = (url: string) => {
  const regex = /^(?:https?:\/\/)?([^\/.]+)\./
  return url.match(regex)?.[1]
}

export const getDateFnsLocale = (locale: string) => {
  const locales = {
    "da-DK": "da",
    "no-NO": "nb",
    "sv-SE": "sv",
    "en-US": "enUS",
  }
  return locales[locale] ?? "enUS"
}

export const toggleListItem = (list: any[], item: any) => {
  const index = list.indexOf(item)
  if (index > -1) {
    list.splice(index, 1)
  } else {
    list.push(item)
  }
  return list
}

export const setNewPeriodPricingsKeys = (record: Record<string, any>) => {
  const dates = [...(record?._dates ?? [])]
  return dates.map((d) => {
    if (d.type !== DATE_TYPES.ALLOCATION_PERIOD) return d
    return {
      ...d,
      data: {
        ...d.data,
        pricing_key: uuidv4(),
      },
    }
  })
}

/**
 * Scrolls a specified element into view.
 * @param element The element to scroll into view
 */
export function scrollToElement(element: HTMLElement): void {
  element.scrollIntoView({
    behavior: "smooth",
    block: "nearest",
    inline: "nearest",
  })
}

export const base64ToBlobUri = (base64, mimetype) => {
  const byteCharacters = Buffer.from(base64, "base64")
  const blob = new Blob([byteCharacters], { type: mimetype })
  const blobUri = URL.createObjectURL(blob)

  return blobUri
}

export const getLastOnline = () => {
  const lastOnline = localStorage.getItem("lastOnline")
  if (lastOnline) {
    return new Date(lastOnline)
  } else {
    return null
  }
}

export const setFilterParams = (params: Record<string, string>) => {
  const searchParams = new URLSearchParams(window.location.search)
  Object.entries(params).forEach(([key, value]) => {
    searchParams.set(key, value)
  })
  window.history.replaceState(
    null,
    "",
    `${window.location.pathname}?${searchParams.toString()}`,
  )
}

export const getFilterParams = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const params: Record<string, string> = {}
  searchParams.forEach((value, key) => {
    params[key] = value
  })
  return params
}
export const createNestedObject = (str, value) => {
  const keys = str.split(".")
  const result = {}
  let current = result

  keys.forEach((key, index) => {
    if (index === keys.length - 1) {
      current[key] = value
    } else {
      current[key] = {}
      current = current[key]
    }
  })

  return result
}

export function isAsyncFunction(func) {
  return func.constructor.name === "AsyncFunction"
}

export function deepMerge<T, U>(target: T, source: U): T & U {
  const isObject = (obj: any): obj is Record<string, any> =>
    !!obj && typeof obj === "object"

  if (!isObject(target) || !isObject(source)) {
    throw new Error("Both target and source need to be objects")
  }

  Object.keys(source).forEach((key) => {
    const targetValue = target[key]
    const sourceValue = source[key]

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = [...targetValue, ...sourceValue]
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = deepMerge(Object.assign({}, targetValue), sourceValue)
    } else {
      target[key] = sourceValue
    }
  })

  return target as T & U
}

export const isJson = (str: string) => {
  if (typeof str !== "string") return false
  try {
    JSON.parse(str)
    return true
  } catch (e) {
    return false
  }
}

export const randomHexColor = () => {
  return "#" + Math.floor(Math.random() * 16777215).toString(16)
}

export const getThemeImageUrl = (url: string) => {
  return url?.startsWith?.("http") ? `url(${url})` : url
}

export function getClassifiedValues(
  fields: IField[],
  data: Record<string, any>,
) {
  const values: Record<string, any> = {}
  fields.map((field) => {
    if (field?.classification) {
      let fieldData = data[field.key] ?? ""
      if (field.classification === "_start_and_end_date") {
        if (
          Array.isArray(fieldData) &&
          fieldData.length >= 2 &&
          fieldData.every((d) => typeof d === "string")
        ) {
          values["_start_date"] = fieldData[0] ?? ""
          values["_end_date"] = fieldData[1] ?? ""
        } else {
          fieldData = [null, null]
        }
      }
      values[field.classification] = fieldData
    }
  })
  return values
}

export const extractClassifiedValues = (
  record: Record<string, any>,
): FieldClassifications => {
  if (!record) return {}
  const values = {}
  Object.keys(CLASSIFICATION_TYPES).map((key) => {
    let data = record[key] ?? ""
    if (key === "_start_and_end_date") {
      if (
        Array.isArray(data) &&
        data.length >= 2 &&
        data.every((d) => typeof d === "string")
      ) {
        values["_start_date"] = data[0] ?? ""
        values["_end_date"] = data[1] ?? ""
      } else {
        data = [null, null]
      }
    }
    if (key === "_allocation") {
      data = !data || isNaN(Number(data)) ? 100 : data
    }
    values[key] = data
  })
  return values
}

export const checkTripletexAccess = (app: IApp, spacerUserUid: string) => {
  return (
    !app?.settings?.has_access?.length ||
    app?.settings?.has_access?.some((uid) => uid === spacerUserUid)
  )
}

export const isArrayofStrings = (variable: any): variable is string[] => {
  return (
    Array.isArray(variable) &&
    variable.every((element) => typeof element === "string")
  )
}

export const hasTaskEditAccess = (users: TTaskUser[], spaceUser: string) => {
  return users?.some(
    (u) =>
      u?.space_user_uid === spaceUser &&
      intersection(u.roles, ["OWNER", "KAM"])?.length > 0,
  )
}
