import { BasicButton } from "@/components/buttons/BasicButton"
import FolderSelector from "@/components/storage/FolderSelector"
import { IFile, IFolder } from "@/features/storage/types"
import { removeAttachments, uploadFiles } from "@/features/storage/utils/api"
import { FolderIcon } from "@/icons/folder"
import {
  faCloudDownload,
  faFiles,
  faTrashCan,
} from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Ring } from "@uiball/loaders"
import { useTranslation } from "next-i18next"
import Link from "next/link"
import { ChangeEvent, useEffect, useRef, useState } from "react"
import { toast } from "sonner"

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 1024 * 1024 * 25
const API_URL = process.env.NEXT_PUBLIC_API_URL

interface IMultiFileUpload {
  maxFileSizeInBytes?: number
  label?: string
  files?: IFile[]
  buttonText?: string
  reference?: string
  destination?: string
  disableUndo?: boolean
  onSuccess?(files: IFile[]): void
  onRemove?(file: IFile): void
}

export const MultiFileUpload = ({
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  label,
  files,
  reference,
  disableUndo,
  buttonText = "Upload files",
  destination,
  onSuccess,
  onRemove,
}: IMultiFileUpload) => {
  const { t } = useTranslation("common")
  const [value, setValue] = useState<string>("")
  const [_files, setFiles] = useState<IFile[]>([])
  const [destFolder, setDestFolder] = useState<IFolder>()
  const [showSelector, setShowSelector] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const fileInputField = useRef<HTMLInputElement>(null)
  const timeout = useRef<NodeJS.Timeout>()
  const filesBackup = useRef<IFile[]>([])

  useEffect(() => {
    if (files?.length) {
      setFiles(files)
      filesBackup.current = files
    }
  }, [files])

  const handleUploadBtnClick = () => {
    if (!destFolder) {
      setShowSelector(true)
    } else {
      fileInputField.current.click()
    }
  }

  const onFolderSelect = (folder: IFolder) => {
    setShowSelector(false)
    setDestFolder(folder)
  }

  const onUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      setValue("")
      setLoading(true)
      let payloadSize = 0
      const { files } = e.target
      if (files.length > 0) {
        const formData = new FormData()
        for (let i = 0; i < files.length; i++) {
          const file = files[i]
          payloadSize += file.size
          formData.append("files", file)
        }

        const res = await uploadFiles(destFolder.uid, formData)

        if (res.success) {
          toast.success(t("files_has_been_uploaded"))
          onSuccess?.(res.files)
        } else {
          toast.error(t("something_went_wrong"))
        }
      }
    } catch (error) {
      toast.error(t("something_went_wrong"))
    } finally {
      setLoading(false)
    }
  }

  const _onRemove = async (file: IFile) => {
    const filtered = _files.filter(
      (f) => f?.attachment_id !== file.attachment_id || f.uid !== file.uid,
    )
    setFiles(filtered)

    if (disableUndo) {
      if (file?.attachment_id) {
        await removeAttachments([file?.attachment_id])
      }
      onSuccess?.(filtered)
      onRemove?.(file)
      filesBackup.current = filtered
      return
    }

    toast.success(
      <div className="flex flex-col gap-2">
        <p>{t("file_has_been_deleted")}</p>
        <button
          className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
          onClick={undo}
        >
          {t("undo")}
        </button>
      </div>,
    )

    timeout.current = setTimeout(async () => {
      if (file?.attachment_id) {
        await removeAttachments([file?.attachment_id])
      }
      onSuccess?.(filtered)
      onRemove?.(file)
      filesBackup.current = filtered
    }, 3500)
  }

  const undo = () => {
    setFiles(filesBackup.current)
    onSuccess?.(filesBackup.current)
    clearTimeout(timeout.current)
  }

  return (
    <div>
      <FolderSelector
        open={showSelector}
        close={() => setShowSelector(false)}
        onSelect={onFolderSelect}
        destination={destination}
      />
      <div className="flex w-full items-center justify-between">
        {label && <p className="text-xs font-medium text-gray-500">{label}</p>}
        {!!destFolder && (
          <div
            onClick={() => setShowSelector(true)}
            className={`group flex cursor-pointer items-center gap-1`}
          >
            <FolderIcon className="w-4 group-hover:text-blue-500" />
            <p className="text-xs font-medium text-gray-500 group-hover:text-blue-500">
              {destFolder.name}
            </p>
          </div>
        )}
      </div>
      <BasicButton
        className="mt-2 flex w-full items-center justify-center"
        onClick={handleUploadBtnClick}
      >
        <div className="mx-auto flex items-center">
          {loading ? (
            <Ring color="var(--color-blue-500)" size={28} />
          ) : (
            <div className="text-custom-gray-dark flex items-center gap-2">
              <FontAwesomeIcon icon={faFiles} />
              <p className="mr-4 text-sm font-medium">
                {!destFolder ? t("select_a_folder") : buttonText}
              </p>
            </div>
          )}
          <input
            type="file"
            ref={fileInputField}
            onChange={onUpload}
            value={value}
            multiple
            className="hidden"
          />
        </div>
      </BasicButton>
      {_files?.map((file) => (
        <div
          key={file.uid}
          className={`flex w-full items-center border-b border-gray-300 py-2 first:pt-4 last:border-b-0 last:pb-4`}
        >
          <div className="text-primary w-5/6 text-xs font-medium">
            <Link href={`${API_URL}/storage/files/${file.uid}/download`}>
              <div className="flex items-center">
                <div className="mr-2">
                  <FontAwesomeIcon icon={faCloudDownload} />
                </div>
                <p className="break-all">{file.filename}</p>
              </div>
            </Link>
          </div>
          <div
            className="ml-auto cursor-pointer text-right hover:text-red-400"
            onClick={() => _onRemove(file)}
          >
            <FontAwesomeIcon icon={faTrashCan} />
          </div>
        </div>
      ))}
    </div>
  )
}
