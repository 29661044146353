import { Pulsar } from "@uiball/loaders"

export const Loader = ({
  className = "",
  firstTimer = 2500,
  secondTimer = 10000,
}) => {
  return (
    <div
      className={`mx-auto mt-4 flex flex-col items-center space-x-2 ${className}`}
    >
      <Pulsar size={40} speed={2} color="var(--color-blue-500)" />
    </div>
  )
}
