import { faSpinnerThird } from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "core/components/Button"
import { useIntersectionObserver } from "hooks/useIntersectionObserver"
import { useTranslation } from "next-i18next"
import { useEffect, useRef } from "react"
import { useMarkAllNotificationsAsRead } from "../hooks/useMarkAllNotificationsAsRead"
import { TNotification } from "../types/notification"
import { NotificationCategory } from "../types/notificationCategory"
import { NotificationCard } from "./NotificationCard"

type TNotificationListViewProps = {
  category: NotificationCategory
  notifications: TNotification[]
  isFetching: boolean
  onEndReached: () => void
}

export const NotificationListView = ({
  category,
  notifications,
  isFetching,
  onEndReached,
}: TNotificationListViewProps) => {
  const { t } = useTranslation("notifications")
  const markAllNotificationsAsRead = useMarkAllNotificationsAsRead()
  const ref = useRef<HTMLDivElement>(null)
  const entry = useIntersectionObserver(ref, {})
  const isReachingEnd = entry?.isIntersecting

  useEffect(() => {
    if (isReachingEnd) {
      onEndReached()
    }
  }, [isReachingEnd])

  return (
    <div className="h-full overflow-y-auto bg-white">
      {category === NotificationCategory.ALL && notifications.length > 0 && (
        <div className="flex items-center justify-end pb-1 pl-3 pr-1 pt-2">
          <Button variant="text" onClick={markAllNotificationsAsRead}>
            {t("actions.mark_all_as_read")}
          </Button>
        </div>
      )}
      {notifications.map((notification) => (
        <NotificationCard key={notification.uid} notification={notification} />
      ))}
      <div ref={ref} />
      {isFetching && (
        <div className="py-5 text-center">
          <FontAwesomeIcon size="lg" icon={faSpinnerThird} spin />
        </div>
      )}
    </div>
  )
}
