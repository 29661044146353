import { fetcher } from "@/utils/api"
import { queryClient } from "pages/_app"

export const useMarkNotificationAsUnread = () => {
  return async (uid: string) => {
    try {
      await fetcher(`/notifications/v2/${uid}/unread`, {
        method: "PUT",
      })
      queryClient.invalidateQueries({
        queryKey: ["notifications"],
      })
    } catch (error) {
      console.log(error)
    }
  }
}
