import { fetcher } from "@/api/index"

export const getTask = async (uid: string) => {
  return await fetcher(`/tasks/${uid}`)
}

export const createTask = ({ ...body } = {}) => {
  return fetcher(`/tasks`, {
    method: "POST",
    body: JSON.stringify(body),
  })
}

export const createLiteTask = (owner?: string) => {
  return fetcher(`/tasks/lite${owner ? `?space=${owner}` : ""}`, {
    method: "POST",
  })
}

export const attachFilesToTask = (
  files: string[],
  uid: string,
  fieldKey?: string,
) => {
  return fetcher(`/tasks/${uid}/attachments`, {
    method: "POST",
    body: JSON.stringify({ files, fieldKey }),
  })
}

export const importFromTask = (
  uid: string,
  from: string,
  mapping?: Record<string, string>,
) => {
  return fetcher(`/tasks/${uid}/import`, {
    method: "POST",
    body: JSON.stringify({ from, mapping }),
  })
}

export const attachFilesToSubtasks = (files: string[], parent: string) => {
  return fetcher(`/tasks/${parent}/subtasks/attachments`, {
    method: "POST",
    body: JSON.stringify({ files }),
  })
}

export const editTask = (project: string, { title }: any) => {
  return fetcher(`/tasks/${project}`, {
    method: "PUT",
    body: JSON.stringify({ title }),
  })
}

export const editAttribute = (
  project: string,
  attribute: string,
  value: any,
) => {
  return fetcher(`/tasks/${project}/attributes/${attribute}`, {
    method: "PUT",
    body: JSON.stringify(value),
  })
}

export const getMinibids = (uid: string) => {
  return fetcher(`/tasks/${uid}/minibids`)
}

export const getMinibid = (uid: string, parent: string) => {
  return fetcher(`/tasks/${parent}/minibids/${uid}`)
}

export const toggleInterest = (
  uid: string,
  state: boolean,
  feedback?: { reason: string; feedback?: string },
): Promise<{ success: boolean; url?: string }> => {
  return fetcher(`/tasks/${uid}/interest`, {
    method: "POST",
    body: JSON.stringify({ state, ...feedback }),
  })
}

export const syncMinibid = (uid: string, parent: string): Promise<void> => {
  return fetcher(`/minibids/${uid}/sync`, {
    method: "POST",
    body: JSON.stringify({ task: parent }),
  })
}

export const deleteRfq = (uid: string) => {
  return fetcher(`/minibids/${uid}`, {
    method: "DELETE",
  })
}
