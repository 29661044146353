import { cn } from "@/utils/helpers"
import { IAllProps } from "@tinymce/tinymce-react"
import { TextEditor } from "../TextEditor"

interface ITextArea extends IAllProps {
  readOnly?: boolean
  onChange?: (event) => void
  label?: string
  placeholder?: string
  error?: string
}

export const TextArea = ({
  readOnly = false,
  value,
  onChange,
  label,
  placeholder,
  error,
  ...props
}: ITextArea) => {
  if (Array.isArray(value)) value = value.join("")

  return (
    <div className="w-full">
      {label && (
        <p className="mb-2 text-xs font-medium text-gray-500">{label}</p>
      )}
      <div
        className={cn(
          "w-full",
          error && "rounded-[10px] border border-red-500",
        )}
      >
        <TextEditor
          {...props}
          placeholder={placeholder}
          readOnly={readOnly}
          value={value}
          onEditorChange={(value) => {
            const event = {
              target: {
                value,
              },
            }
            onChange?.(event)
          }}
        />
      </div>
    </div>
  )
}
