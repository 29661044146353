import { selectedSpaceAtom } from "@/atoms/spaces"
import { CURRENCIES, DEFAULT_CURRENCY, DEFAULT_LOCALE } from "@/utils/constants"
import { faChevronDown } from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import CurrencyInput, {
  CurrencyInputProps,
  formatValue as _formatValue,
} from "react-currency-input-field"
import { useRecoilValue } from "recoil"
import { cn, moneyConversion, moneyDeconversion } from "../../../utils/helpers"
import { roundToThreeDecimals } from "../../utils/helper"
import { DropdownGeneric } from "../fields"

type MoneyInputProps = {
  value?: any
  onChange?: (result) => void
  className?: string
  label?: string
  error?: string
  disableCurrencySelection?: boolean
  renderCurrencyContainer?: boolean
}

export const formatMoneyInputs = ({
  value,
  currency,
  locale,
  space,
}: MoneyInputProps["value"] & {
  space?: Record<string, any>
  locale?: string
  label?: string
}) => {
  currency = currency ?? space?.currency ?? DEFAULT_CURRENCY
  value = value ?? 0
  locale = locale ?? space?.locale ?? DEFAULT_LOCALE

  return { value, currency, locale }
}

const convertToString = (value, deconversion = true) => {
  if (deconversion) value = moneyDeconversion(Number(value))
  return Number(roundToThreeDecimals(value)).toString()
}

export const formatValue = (
  value: MoneyInputProps["value"],
  deconversion = true,
) => {
  let { value: _value, currency, locale } = formatMoneyInputs(value)

  return _formatValue({
    value: convertToString(_value, deconversion),
    intlConfig: { locale, currency },
  })
}

export const MoneyInput = ({
  onChange,
  className,
  label,
  value,
  error,
  disableCurrencySelection = false,
  renderCurrencyContainer = true,
}: MoneyInputProps) => {
  const space = useRecoilValue(selectedSpaceAtom)
  const {
    value: _value,
    currency,
    locale,
  } = formatMoneyInputs({ ...value, space })
  const [initialized, setInitialized] = useState(false)

  const [inputValue, setInputValue] = useState<string>(convertToString(_value))

  const [valueFloat, setValueFloat] = useState<number>(Number(_value))
  const [_currency, setCurrency] = useState<string>(currency)

  useEffect(() => {
    if (_value !== inputValue) {
      setInputValue(convertToString(_value))
    }
    if (currency !== _currency) {
      setCurrency(currency)
    }
  }, [_value, currency])

  useEffect(() => {
    if (!initialized) {
      setInitialized(true)
      return
    }
    onChange?.({ value: valueFloat, currency: _currency, locale })
  }, [valueFloat, _currency])

  const handleChange: CurrencyInputProps["onValueChange"] = (
    value,
    _,
    values,
  ) => {
    setValueFloat(moneyConversion(values.float))
    setInputValue(value)
  }

  return (
    <div
      className={cn(
        "border-custom-gray group relative flex w-full items-center justify-between gap-1 rounded-xl border bg-white px-3 ring-inset group-focus:ring-2",
        error && "border-red-500",
        className,
      )}
    >
      <div className="flex flex-col gap-0.5">
        <span
          className={cn(
            "text-custom-gray-dark pointer-events-none bg-inherit text-xs font-light leading-none transition-all",
          )}
        >
          {label}
        </span>

        <CurrencyInput
          value={inputValue}
          className={cn(
            className,
            "w-full border-none py-0 text-sm text-gray-500 placeholder-gray-500 outline-none focus:bg-white",
          )}
          onValueChange={handleChange}
          intlConfig={{ locale, currency: _currency }}
          disableAbbreviations={true}
          decimalsLimit={3}
        />
      </div>
      {renderCurrencyContainer && (
        <div className="text-xs text-gray-400">
          {disableCurrencySelection ? (
            <>{_currency}</>
          ) : (
            <DropdownGeneric
              collection={CURRENCIES}
              renderItem={({ item }) => (
                <div
                  onClick={() => setCurrency(item)}
                  className="cursor-pointer rounded-md px-2 py-1 hover:bg-gray-100"
                >
                  {item}
                </div>
              )}
              button={
                <div className="flex items-center gap-1">
                  <p>{_currency}</p>
                  <FontAwesomeIcon icon={faChevronDown} />
                </div>
              }
            />
          )}
        </div>
      )}
    </div>
  )
}
