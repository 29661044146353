import { singleton } from "tsyringe"
import { Dictionary } from "tsyringe/dist/typings/types"

@singleton()
export class CacheProvider {
  private _locks: Record<string, Promise<any>[]> = {}
  private _cache: Dictionary<any> = {}

  constructor() {}

  async add<T>(key: string, value: T) {
    this._execute(key, () => this._set(key, value))
  }

  async _set(key: string, value: any) {
    this._cache[key] = value
  }

  async get<T>(key: string): Promise<T | undefined> {
    const value: T = await this._execute(key, () => this._get(key))
    return value
  }

  async _get(key: string) {
    return this._cache[key]
  }

  async remove(key: string) {
    await this._execute(key, () => this._remove(key))
  }

  async _remove(key: string) {
    delete this._cache[key]
  }

  async exist(key: string): Promise<boolean> {
    return await this._execute(key, () => this._exist(key))
  }

  async _exist(key: string): Promise<boolean> {
    return key in this._cache ? true : false
  }

  async clear(): Promise<void> {
    await this._execute("clear", () => this._clear())
  }

  async _clear() {
    this._cache = {}
  }

  async _execute<T>(lockName: string, task: () => Promise<T>): Promise<T> {
    if (!this._locks[lockName]) {
      this._locks[lockName] = []
    }

    const locks = this._locks[lockName]
    const isFirst = locks.length === 0
    let unlock = () => {}

    const newLock = new Promise<void>((resolve) => {
      unlock = resolve
    })

    locks.push(newLock)
    if (!isFirst) {
      const predecessorLock = locks[locks.length - 2]
      await predecessorLock
    }

    try {
      return await task()
    } catch (error) {
      throw error
    } finally {
      locks.splice(0, 1)
      unlock()
    }
  }
}
