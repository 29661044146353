import { getItem, removeItem } from "../../browserStorage"
import { fetcher } from "../index"

export const getSpaceFromToken = () => {
  const authToken = getItem("_ft_a_")

  return parseJwt(authToken)?.space
}

export function parseJwt(token) {
  if (!token) {
    return
  }
  const base64Url = token.split(".")[1]
  const base64 = base64Url.replace("-", "+").replace("_", "/")
  return JSON.parse(window.atob(base64))
}

// export const magicLink = ({
//   email,
//   returnUrl,
// }: {
//   email: string
//   returnUrl?: string
// }) => {
//   return fetcher("/auth/magic_link", {
//     method: "POST",
//     body: JSON.stringify({ email, returnUrl }),
//   })
// }
export const magicLink = async ({
  email,
  returnUrl,
}: {
  email: string
  returnUrl?: string
}) => {
  try {
    const response = await fetcher("/auth/magic_link", {
      method: "POST",
      body: JSON.stringify({ email, returnUrl }),
    })
    return response
  } catch (error) {
    console.error("Error sending magic link:", error)
    return undefined
  }
}

export const getAuthLink = (
  strategy: "google" | "microsoft",
  params?: { returnUrl?: string },
) => {
  return `${process.env["NEXT_PUBLIC_API_URL"]}/auth/${strategy}?success_uri=/?returnUrl=${params.returnUrl ?? "/"}`
}

export const signUp = async ({
  firstname,
  lastname,
  phone,
}: {
  firstname: string
  lastname: string
  phone: string
}) => {
  return await fetcher(`/auth/signup`, {
    method: "POST",
    body: JSON.stringify({ firstname, lastname, phone }),
  })
}

export const signOut = () => {
  if (window._cio) {
    window._cio.reset()
  }
  localStorage.clear()

  removeItem("_ft_a_")
  window.location.assign("/")
}
