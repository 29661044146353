import { Dictionary } from "@/core/collections/dictionary"

const classRegistry: Dictionary<any> = {}

export function Serializable(constructor: Function) {
  let className: string = constructor.name
  if (
    className.endsWith("1") ||
    className.endsWith("2") ||
    className.endsWith("3")
  ) {
    className = className.slice(0, -1)
  }

  classRegistry[className] = constructor
}

export function getClassType(className: string): any {
  return classRegistry[className]
}

export const registerClass = <T>(ctor: new () => T) => {
  const instance = new ctor()
}
