import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { HTMLAttributes } from "react"
import { Icon } from "../../../features/icons/components/IconLoader"

interface IDropdownItem extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {
  title: string | JSX.Element
  icon?: IconDefinition | string
  selected?: boolean
  className?: string
}

export const DropdownItem = ({
  title,
  className,
  icon,
  selected,
  ...props
}: IDropdownItem) => {
  return (
    <div
      {...props}
      className={`m-1 flex cursor-pointer items-center gap-2 rounded-md px-2 py-1 text-sm hover:bg-neutral-100 ${className ?? ""}`}
    >
      {!!icon && selected ? (
        <Icon
          icon={icon}
          className={`invisible ${selected ? "!visible" : ""}`}
        />
      ) : null}
      {typeof title === "string" ? <p>{title}</p> : title}
    </div>
  )
}
