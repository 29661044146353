import { Popover } from "antd"
import { useRecoilValue } from "recoil"
import { cn } from "../../../utils/helpers"
import { onlineAtom } from "@/atoms/online"

export const ConnectionDot = ({
  children,
  position = "bottomRight",
}: {
  children?: React.ReactElement
  position?: "bottomRight" | "bottomLeft" | "topRight" | "topLeft"
}) => {
  const online = useRecoilValue(onlineAtom)

  const getPositionStyling = () => {
    switch (position) {
      case "bottomLeft":
        return "bottom-0 left-0"
      case "topRight":
        return "top-0 right-0"
      case "topLeft":
        return "top-0 left-0"
      case "bottomRight":
      default:
        return "bottom-0 right-0"
    }
  }

  const getDotColor = () => {
    if (online) {
      return "bg-green-500"
    } else {
      return "bg-red-500"
    }
  }

  const classes = cn("bottom-0 right-0", getPositionStyling(), getDotColor())
  const realtimeClasses = cn("bottom-0 right-0", getDotColor())
  return (
    <div className="relative">
      {children}
      <Popover
        trigger={"hover"}
        content={
          <>
            <div className="flex flex-col space-y-2 text-xs">
              <div className="flex items-center space-x-2">
                <div className="h-2.5 w-2.5 rounded-full bg-green-500" />
                <span>User online</span>
              </div>
              <div className="flex items-center space-x-2">
                <div
                  className={`h-2.5 w-2.5 ${realtimeClasses} rounded-full`}
                />
                <span>Real-time events</span>
              </div>
            </div>
          </>
        }
        placement="left"
      >
        <div
          className={`absolute ${classes} h-2.5 w-2.5 rounded-full border border-inherit`}
        />
      </Popover>
    </div>
  )
}
