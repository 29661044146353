import * as pako from "pako"
import { Dictionary } from "@/core/collections/dictionary"
import { JsonUtil } from "@/core/api/utils/json_util"
import { ResponseModel } from "@/core/api/models/response_model"
import { ProtoResponseModel } from "@/core/api/proto/models/protoResponseModel"
import { getClassType } from "@/core/api/decorators/serializable_decorator"
import { ClassUtil } from "./utils/class_util"

export class ResponseSerializer {
  public static serialize<T>(model: T, useCompression: boolean): Uint8Array {
    const classes: string[] = ClassUtil.getClassNames(model)
    const encData: string = JsonUtil.serializeJson64(model)
    const dataBinary: Uint8Array = new ProtoResponseModel({
      data: encData,
      classes: classes,
    }).serializeBinary()
    if (useCompression) {
      const dataCompressed: Uint8Array = pako.deflate(dataBinary)
      return dataCompressed
    }

    return dataBinary
  }

  public static deserialize<T>(
    response: ResponseModel,
    classes: Dictionary<any> | null = null,
  ): T | null {
    const data: Uint8Array = response.iscompressed
      ? pako.inflate(response.data)
      : response.data
    const model: T | null = ResponseSerializer._deserialize_binary(
      data,
      classes,
    )

    return model
  }

  private static _deserialize_binary<T>(
    data: Uint8Array,
    classes: Dictionary<any> | null = null,
  ): T | null {
    const response: ProtoResponseModel =
      ProtoResponseModel.deserializeBinary(data)
    if (classes === null) {
      classes = {}
      response.classes.map((name: string) => {
        if (classes) {
          classes[name] = getClassType(name)
        }
      })
    }

    const model: T | null = JsonUtil.deserializeJson64<T>(
      response.data,
      classes,
    )

    return model
  }
}
