import * as FontAwesomeIconsBrands from "@awesome.me/kit-44b29310a6/icons/classic/brands"
import * as FontAwesomeIconsLight from "@awesome.me/kit-44b29310a6/icons/classic/light"
import * as FontAwesomeIconsRegular from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import * as FontAwesomeIconsSolid from "@awesome.me/kit-44b29310a6/icons/classic/solid"
import * as FontAwesomeIconsDoutone from "@awesome.me/kit-44b29310a6/icons/duotone/solid"
import { IconType } from "../components/IconLoader"

const icons = {
  fontawesome: {
    regular: FontAwesomeIconsRegular,
    solid: FontAwesomeIconsSolid,
    duotone: FontAwesomeIconsDoutone,
    light: FontAwesomeIconsLight,
  },
}

export default {
  ...icons,
  getIcons: (type?: IconType) => {
    return {
      ...icons.fontawesome?.[type ?? "regular"],
      ...FontAwesomeIconsBrands,
    }
  },
}
