import Avatar from "@/components/avatar"
import { SheetClose } from "@/shared/components/ui/sheet"
import { cn } from "@/utils/helpers"
import {
  ImageWithFallback,
  ORGANISATION_AVATERS,
} from "@/utils/ImageWithFallback"
import { faSpinnerThird } from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { faStar } from "@awesome.me/kit-44b29310a6/icons/classic/solid"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "core/components/Button"
import { Tag } from "core/components/Tag"
import { useTimeFromNowShort } from "core/hooks/useTimeFromNowShort"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import { useTranslation } from "next-i18next"
import Link from "next/link"
import { useDeleteNotification } from "../hooks/useDeleteNotification"
import { useMarkNotificationAsRead } from "../hooks/useMarkNotificationAsRead"
import { useNotificationMetadata } from "../hooks/useNotificationMetadata"
import { TNotification } from "../types/notification"
import { NotificationActionButton } from "./NotificationActionButton"
dayjs.extend(relativeTime)

type TNotificationCardProps = {
  notification: TNotification
}

export const NotificationCard = ({ notification }: TNotificationCardProps) => {
  const { t } = useTranslation("notifications")
  const { title, tagLabel, tagVariant } = useNotificationMetadata(notification)
  const markNotificationAsRead = useMarkNotificationAsRead()
  const {
    isLoading,
    isDeleted,
    canUndo,
    deleteNotification,
    undoDeleteNotification,
  } = useDeleteNotification()
  const timeFromNowShort = useTimeFromNowShort(notification.created)

  return (
    <div
      className={cn(
        "relative overflow-hidden transition-all duration-700",
        isDeleted ? (canUndo ? "max-h-16" : "max-h-0") : "max-h-96",
      )}
    >
      <div
        className={cn(
          "absolute bottom-0 left-0 right-0 top-0 flex items-center justify-between border-b border-solid border-gray-200 pl-4 pr-1",
          "transition-opacity duration-700 ease-in-out",
          isDeleted
            ? canUndo
              ? "opacity-100"
              : "opacity-0"
            : "pointer-events-none opacity-0",
        )}
      >
        <div className="text-sm">{t("notification_deleted")}</div>
        <Button
          variant="text"
          onClick={() => undoDeleteNotification(notification.uid)}
        >
          {t("actions.undo_delete_notification")}
        </Button>
      </div>
      <SheetClose
        asChild
        className={cn(
          "transition-opacity duration-500",
          isDeleted ? "pointer-events-none opacity-0" : "opacity-100",
        )}
      >
        <Link
          href={`/r/${notification.link}`}
          onClick={() => markNotificationAsRead(notification.uid)}
        >
          <div className="hover:bg-primary/10 flex cursor-pointer space-x-2 border-b border-solid border-gray-200 bg-white py-4 pl-2 pr-1">
            <div>
              <div className="flex items-center">
                <div
                  className={`mr-2 h-2 w-2 rounded-full ${!notification.is_read && "bg-red-400"}`}
                />
                <div className="relative">
                  {notification.sender_name === "SYSTEM" && (
                    <ImageWithFallback
                      className="max-w-[40px] rounded-full"
                      width={40}
                      height={40}
                      alt={notification.task?.customer?.name}
                      src={ORGANISATION_AVATERS(
                        notification.task?.customer?.uid,
                      )}
                    />
                  )}
                  {notification.sender_name !== "SYSTEM" && (
                    <Avatar
                      email={
                        notification?.candidate?.email ??
                        notification?._user?.email
                      }
                      size={40}
                    />
                  )}
                  {notification.is_owner && (
                    <div className="absolute -bottom-0.5 -right-0.5 flex h-4 w-4 items-center justify-center rounded-full bg-white">
                      <FontAwesomeIcon
                        className="text-orange-400"
                        icon={faStar}
                        size="xs"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-1 flex-col">
              {title !== undefined && (
                <div
                  className="mb-2 text-left text-sm"
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              )}
              {tagLabel !== undefined && tagVariant !== undefined && (
                <div className="flex items-start">
                  <Tag label={tagLabel} variant={tagVariant} hasBadge />
                </div>
              )}
              {notification.task && (
                <div className="mt-2 flex rounded-md border border-solid border-gray-300 p-3">
                  {notification.task?.customer?.uid !== undefined && (
                    <div className="mr-1.5 h-9 w-9 items-center justify-items-center overflow-hidden rounded-full border-2 border-solid border-white">
                      <ImageWithFallback
                        className="h-full w-full object-contain"
                        width={64}
                        height={64}
                        alt={notification.task.customer.name}
                        src={ORGANISATION_AVATERS(
                          notification.task.customer.uid,
                        )}
                      />
                    </div>
                  )}
                  <div>
                    <div className="line-clamp-1 text-sm text-gray-400">
                      {/* TODO Re-implement case number when we have it standardized */}
                      {/* {notification.task?.case_number} &middot;{" "} */}
                      {notification.task.customer?.name}
                    </div>
                    <div className="line-clamp-1 text-sm font-medium text-gray-600">
                      {notification.task.title}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-col items-center">
              <div className="text-xs text-gray-400">{timeFromNowShort}</div>
              <NotificationActionButton
                notification={notification}
                deleteNotification={deleteNotification}
              />
            </div>
          </div>
        </Link>
      </SheetClose>
      <div
        className={cn(
          "absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center bg-white transition-all duration-700",
          isLoading ? "opacity-80" : "pointer-events-none opacity-0",
        )}
      >
        <FontAwesomeIcon size="lg" icon={faSpinnerThird} spin />
      </div>
    </div>
  )
}
