import { Base64 } from "js-base64"
import { Dictionary } from "@/core/collections/dictionary"

export class ClassUtil {
  public static getClassNames(model: any): string[] {
    const classNames: string[] = []
    classNames.push(model.constructor.name)
    for (const key of Object.keys(model)) {
      const value = model[key]
      if (Array.isArray(value) && value.length > 0) {
        const className: string = value[0].constructor.name
        if (!classNames.includes(className)) {
          classNames.push(className)
        }
      }
    }

    return classNames
  }
}
