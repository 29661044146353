import "reflect-metadata"
import { appWithTranslation } from "next-i18next"
import nextI18nConfig from "../next-i18next.config"
import { roboto, inter, robotoMono } from "core/utils/fonts"
import React, { useMemo } from "react"
import { RecoilRoot } from "recoil"

import "@fortawesome/fontawesome-svg-core/styles.css"
import Head from "next/head"
import { useRouter } from "next/router"
import AuthenticationProvider from "../components/authentication"
import SideBar from "../components/sidebar/SideBar"
import "../styles/globals.css"
import "../styles/reactGrid.css"
import { disableDuplicateWarning } from "../utils/recoil"

import { Toaster } from "sonner"

global.console = disableDuplicateWarning(global.console)

import { Header } from "@/components/Header"
import { Loader } from "../components/spinners/loader"

import { Worker } from "@react-pdf-viewer/core"
import { ErrorBoundary } from "@sentry/nextjs"
import packageJson from "../package.json"
import Error500Page from "./500"

import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ThemeProvider } from "context/theme-context"
import { SWRConfig } from "swr"
import { NotificationsProvider } from "../context/notifications"
import PusherProvider from "../context/pusher-context"

export const queryClient = new QueryClient()

function MyApp({ Component, pageProps }) {
  const router = useRouter()

  const pdfjsWorkerVersion: string = useMemo(
    () => packageJson.dependencies["pdfjs-dist"],
    [],
  )

  return (
    <ErrorBoundary fallback={<Error500Page />}>
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <React.Suspense fallback={<Loader />}>
            <SWRConfig
              value={{
                revalidateOnFocus: false,
                revalidateOnReconnect: false,
              }}
            >
              <ThemeProvider>
                <Head>
                  <meta name="robots" content="noindex, nofollow" />
                  <title>Flextribe - Workforce Management System</title>
                  <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
                  />
                </Head>
                <AuthenticationProvider>
                  <main
                    className={`font-sans ${roboto.variable} ${inter.variable} ${robotoMono.variable}`}
                  >
                    <PusherProvider>
                      <NotificationsProvider />
                      <div className="flex h-screen w-screen flex-col overflow-hidden">
                        <Header />
                        <div className="flex h-[calc(100vh-4rem)] overflow-hidden md:flex-row">
                          <SideBar />
                          <div className="h-full w-full overflow-auto bg-blue-100 pb-16 sm:pb-0">
                            <Worker
                              workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsWorkerVersion}/build/pdf.worker.min.js`}
                            >
                              <Component {...pageProps} key={router.asPath} />
                            </Worker>
                          </div>
                          <div id="modal-root"></div>
                        </div>
                      </div>
                    </PusherProvider>
                  </main>
                </AuthenticationProvider>
              </ThemeProvider>
            </SWRConfig>
          </React.Suspense>
        </RecoilRoot>

        <Toaster
          visibleToasts={20}
          richColors
          position="top-right"
          closeButton
          className="pointer-events-auto"
        />
      </QueryClientProvider>
    </ErrorBoundary>
  )
}

export default appWithTranslation(MyApp, nextI18nConfig)
