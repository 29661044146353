import { SupplierTaskContractType } from "@/types/tasks/tasksContractTypes"
import { TTaskUserBody } from "@/types/tasks/users"
import { ITask } from "pages/projects"
import { fetcher } from "../index"

export const getTasksByTemplate = async (template: string) => {
  return await fetcher(`/tasks/templates/${template}`, {
    method: "GET",
  })
}

export const getSubtasks = async (
  task: string,
  fields?: string[],
): Promise<ITask[]> => {
  const fieldsString = fields ? fields.join(",") : ""
  return await fetcher(
    `/tasks/${task}/subtasks${fields ? `?fields=${fieldsString}` : ""}`,
  )
}

export const getRandomTask = async (template: string) => {
  return await fetcher(`/tasks/random?template=${template}`)
}

export const addAssignee = async ({
  task,
  user,
  roles,
}: {
  task: string
  user: string
  roles: string[]
}) => {
  return await fetcher(`/tasks/${task}/assignee`, {
    method: "POST",
    body: JSON.stringify({ user, roles }),
  })
}

export const toggleTaskPin = async (task) => {
  return await fetcher(`/tasks/${task}/pin`, {
    method: "POST",
  })
}

export const addTemplateToTask = async (task, template) => {
  return await fetcher(`/tasks/${task}/template`, {
    method: "POST",
    body: JSON.stringify({ template }),
  })
}

export const getSupplierTaskContracts = async (
  taskUid: string,
  supplierUids: string[],
): Promise<SupplierTaskContractType[]> => {
  return await fetcher(`/contracts/tasks/${taskUid}/suppliers`, {
    method: "POST",
    body: JSON.stringify({ suppliers: supplierUids }),
  })
}

export const updateTask = async (
  task: string,
  payload: Record<string, any>,
) => {
  return await fetcher(`/tasks/${task}`, {
    method: "PUT",
    body: JSON.stringify(payload),
  })
}

export const updateTaskUser = async ({
  task,
  spaceUser,
  role,
  shouldPropagate = false,
}: TTaskUserBody) => {
  return await fetcher(`/tasks/${task}/users/${spaceUser}`, {
    method: "PUT",
    body: JSON.stringify({ spaceUser, role, shouldPropagate }),
  })
}

export const deleteTask = async (uid: string) => {
  return await fetcher(`/tasks/${uid}`, {
    method: "DELETE",
  })
}

export const removeAssignee = async ({ task, user }) => {
  return await fetcher(`/tasks/${task}/assignee`, {
    method: "DELETE",
    body: JSON.stringify({ user }),
  })
}

export const deleteTaskUser = async ({
  task,
  spaceUser,
  shouldPropagate,
}: Omit<TTaskUserBody, "role">) => {
  return await fetcher(`/tasks/${task}/users/${spaceUser}`, {
    method: "DELETE",
    body: JSON.stringify({ shouldPropagate }),
  })
}
