import { meAtom } from "@/atoms/users"
import { useState } from "react"
import { useRecoilValue } from "recoil"

import { signUp } from "@/utils/api/authentication"
import { cn, getThemeImageUrl } from "@/utils/helpers"
import { useTheme } from "context/theme-context"
import { useRouter } from "next/router"
import { PhoneInput, TextInput } from "../../../shared/components/fields"

export function SignUp() {
  const me = useRecoilValue(meAtom)
  const [user, setUser] = useState(me)
  const { push } = useRouter()
  const theme = useTheme()
  const [validPhone, setValidPhone] = useState(false)
  const authImg = getThemeImageUrl(theme?.authimg)
  const authLogo = getThemeImageUrl(theme?.authlogo)

  const handleClick = async () => {
    let error = false

    const phone = user?.phone?.toString()

    if (
      !user?.firstname ||
      !user?.lastname ||
      phone?.length === undefined ||
      !validPhone
    ) {
      error = true
    } else {
      await signUp({
        firstname: user?.firstname,
        lastname: user?.lastname,
        phone: user?.phone,
      })
      window.location.assign("/")
    }

    if (error === false) {
      push("/")
    }
  }

  const handleChangeUser = (key) => (e) => {
    if (e?.target) e = e.target.value

    setUser((user) => ({
      ...user,
      [key]: e,
    }))
  }

  return (
    <div>
      <div
        style={{ backgroundImage: authImg }}
        className="login fixed z-[100] h-full w-full bg-cover px-4 py-16"
      ></div>
      <div className="absolute top-[50%] z-[101] flex w-full translate-y-[-50%]">
        <div
          className={`${
            theme?.login_color ?? theme?.login_screen?.color ?? "text-primary"
          } max-sm:hidden sm:w-7/12 sm:pl-4 sm:pt-40 lg:w-8/12 lg:pl-10 2xl:w-9/12 2xl:pl-60 2xl:pr-60 2xl:pt-80`}
        >
          <p className="font-light sm:text-4xl lg:text-6xl">
            {theme?.login_headline ?? theme?.login_screen?.title}
          </p>
          <p className="pt-8 text-xl md:pr-20 lg:pr-40">
            {theme?.login_description ?? theme?.login_screen?.statement}
          </p>
        </div>
        <div className="ml-auto items-end justify-center max-sm:ml-4 max-sm:mr-4 max-sm:w-full md:mr-4 md:w-5/12 lg:mr-10 lg:w-4/12 2xl:mr-40 2xl:w-3/12">
          <div className="mb-4 mt-16 w-full rounded-xl bg-white p-10 shadow">
            <div
              style={{ backgroundImage: authLogo }}
              className="mx-auto h-16 w-60 bg-contain bg-center bg-no-repeat pb-8"
            ></div>
            <p className="mt-4 py-2 text-center text-xl font-medium">
              Finish your account
            </p>
            <div className="flex w-full items-center justify-between pt-5">
              <hr className="w-full bg-gray-400" />
              <p className="select-none px-4 text-[10px] font-medium uppercase leading-4 text-gray-400">
                Personal
              </p>
              <hr className="w-full bg-gray-400" />
            </div>
            <div className="mt-4 w-full">
              <div className="w-full">
                <p className="pb-2 text-xs font-medium text-gray-500">Email</p>
                <div className="">{user.email}</div>
              </div>
              <div className="mt-4 flex space-x-4">
                <div className="">
                  <TextInput
                    label="First name"
                    value={user?.firstname}
                    onChange={handleChangeUser("firstname")}
                    className={cn({ "border-red-500": !user?.firstname })}
                  />
                  {!user?.firstname?.length && (
                    <p className="text-xs italic text-red-500">*Required</p>
                  )}
                </div>
                <div className="">
                  <TextInput
                    label="Last name"
                    value={user?.lastname}
                    onChange={handleChangeUser("lastname")}
                  />
                  {!user?.lastname?.length && (
                    <p className="text-xs italic text-red-500">*Required</p>
                  )}
                </div>
              </div>
              <div className="mt-4">
                <PhoneInput
                  label="Phone number"
                  value={user?.phone?.toString()}
                  onChange={handleChangeUser("phone")}
                  isValid={validPhone}
                  setIsValid={setValidPhone}
                />{" "}
                {!validPhone && (
                  <p className="text-xs italic text-red-500">
                    *Invalid phone number
                  </p>
                )}
              </div>
            </div>
            <div className="mt-8">
              <button
                role="button"
                className="w-full rounded-xl border bg-blue-600 py-4 text-lg font-medium leading-none text-white hover:opacity-80 disabled:opacity-50"
                onClick={handleClick}
                disabled={!validPhone || !user?.firstname || !user?.lastname}
              >
                Finish signup
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
