import { getUsers } from "@/features/people/utils/api"
import { TaskState } from "@/features/task_states/utils"
import { ITask } from "pages/projects"
import { PREFERRED_SUPPLIERS_APP, getAppData } from "../apps"
import { fetcher } from "../index"

export const suppliersCollection = async (space?: string) => {
  return await getAppData(
    PREFERRED_SUPPLIERS_APP,
    space ? `?space=${space}` : "",
  )
}

export const usersCollection = async () => {
  const data = await getUsers()
  return data.filter((u: any) => u.type === "INTERNAL")
}

export const getTasks = async (state?: TaskState["key"]): Promise<ITask[]> => {
  return await fetcher(`/tasks${state ? `?state=${state}` : ""}`)
}

export const subtasksCollection = async (
  reference?: string,
): Promise<ITask[]> => {
  return await fetcher(`/tasks${reference ? `/${reference}/subtasks` : ""}`)
}

export const siblingsCollection = async (uid) => {
  return await fetcher(`/tasks/${uid}/siblings`)
}

export const taskSuppliersCollection = async (reference) => {
  return await fetcher(
    `/collections/task_suppliers${reference ? `/${reference}` : ""}`,
  )
}
