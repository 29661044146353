export const disableDuplicateWarning = (globalConsole) => {
  const memoize = (fn) => {
    let cache = {}
    return (...args) => {
      let n = args[0]
      if (n in cache) {
        return cache[n]
      } else {
        let result = fn(n)
        cache[n] = result
        return result
      }
    }
  }

  const mutedConsole = memoize((console) => ({
    ...console,
    warn: (...args) =>
      args[0].includes("Duplicate atom key") ? null : console.warn(...args),
    error: (...args) => {
      if (args.length === 0 || typeof args[0] !== "object") {
        console.error(...args)
        return
      }

      if (
        typeof args[0].message === "string" &&
        args[0].message.includes("Duplicate atom key")
      ) {
        return
      }
      console.error(...args)
    },
  }))
  return mutedConsole(globalConsole)
}
