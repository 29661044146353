import * as pako from "pako"
import { Dictionary } from "@/core/collections/dictionary"
import { JsonUtil } from "@/core/api/utils/json_util"
import { getClassType } from "@/core/api/decorators/serializable_decorator"
import { ProtoRequestModel } from "./proto/models/protoRequestModel"
import { RequestModel } from "./models/request_model"
import { ClassUtil } from "./utils/class_util"

export class RequestSerializer {
  public static serialize<T>(model: T, useCompression: boolean): Uint8Array {
    const classes: string[] = ClassUtil.getClassNames(model)
    const encData: string = JsonUtil.serializeJson64(model)
    const dataBinary: Uint8Array = new ProtoRequestModel({
      data: encData,
      classes: classes,
    }).serializeBinary()
    if (useCompression) {
      const dataCompressed: Uint8Array = pako.deflate(dataBinary)
      return dataCompressed
    }

    return dataBinary
  }

  public static deserialize<T>(
    request: RequestModel,
    classes: Dictionary<any> | null = null,
  ): T | null {
    const data: Uint8Array = request.iscompressed
      ? pako.inflate(request.data)
      : request.data
    const model: T | null = RequestSerializer._deserialize_binary(data, classes)

    return model
  }

  private static _deserialize_binary<T>(
    data: Uint8Array,
    classes: Dictionary<any> | null = null,
  ): T | null {
    const request: ProtoRequestModel = ProtoRequestModel.deserializeBinary(data)
    if (classes === null) {
      classes = {}
      request.classes.map((name: string) => {
        if (classes) {
          classes[name] = getClassType(name)
        }
      })
    }

    const model: T | null = JsonUtil.deserializeJson64<T>(request.data, classes)

    return model
  }
}
