import { atom } from "recoil"

export const SOCKET_STATES = {
  CONNECTING: "connecting",
  CONNECTED: "connected",
  DISCONNED: "disconnected",
}

export const socketAtom = atom({
  key: "socketAtom",
  default: {
    state: SOCKET_STATES.CONNECTING,
    subscribed_to: [],
  },
})
