import { injectable } from "tsyringe"
import { IExtendedTemplate } from "@/features/templates/types"
import { ResponseModel } from "@/core/generated/api/models/response_model"
import { ResponseSerializer } from "@/core/generated/api/response_serializer"
import { FetchClient } from "@/core/client/fetch_client"
import { ITask } from "../types/itask"
import { TaskSidebarModel } from "../models/taskSideBarModel"

const TASK_TEMPLATE_APP = "52e7c717-d7fe-48fb-8916-61b8fea4ecbb"

@injectable()
export class TaskRepository {
  private _client: FetchClient

  constructor(client: FetchClient) {
    this._client = client
  }

  async getTasksAll(
    state: string,
    templateId: string = "",
    templateType: string = "",
  ): Promise<ITask[]> {
    const url: string = `/tasks${state ? `?state=${state}&templateId=${templateId}&templateType=${templateType}` : ""}`
    const tasks: ITask[] = await this._client.fetchAsync(url)
    return tasks
  }

  async getTasks(
    state: string,
    templateId: string,
    templateType: string,
    skip: number,
    num: number,
  ): Promise<ITask[]> {
    //    const url: string = `/tasks${state ? `?state=${state}&skip=${skip}&num=${num}` : ""}`
    const url: string = `/tasks${state ? `?state=${state}&templateId=${templateId}&templateType=${templateType}&skip=${skip}&num=${num}` : ""}`
    const tasks: ITask[] = await this._client.fetchAsync(url)
    return tasks
  }

  async getExtendedTemplate(templateId: string): Promise<IExtendedTemplate> {
    const url: string = `/apps/${TASK_TEMPLATE_APP}/data/templates/${templateId}`
    const template: IExtendedTemplate = await this._client.fetchAsync(url)
    return template
  }

  async getSidebarTemplates(): Promise<TaskSidebarModel | null> {
    const url: string = `/tasks/sidebar`
    const response: ResponseModel = await this._client.fetchAsync(url)
    if (response === undefined) {
      return null
    }

    const model: TaskSidebarModel | null =
      ResponseSerializer.deserialize<TaskSidebarModel>(response)

    return model
  }

  async updateTask(taskId: string, payload: Record<string, any>): Promise<any> {
    return await this._client.fetchAsync(`/tasks/${taskId}`, {
      method: "PUT",
      body: JSON.stringify(payload),
    })
  }

  async toggleInterest(
    uid: string,
    state: boolean,
    feedback?: { reason: string; feedback?: string },
  ): Promise<boolean> {
    const url: string = `/tasks/${uid}/interest`
    const res = await this._client.fetchAsync(url, {
      method: "POST",
      body: JSON.stringify({ state, ...feedback }),
    })

    return res
  }
}
