export enum NotificationTopics {
  ADDED_TO_TASK_TEAM = "ADDED_TO_TASK_TEAM",
  NEW_MESSAGE = "NEW_MESSAGE",
  INTERESTED_IN_TASK = "INTRESTED_IN_TASK",
  NOT_INTERESTED_IN_TASK = "NOT_INTRESTED_IN_TASK",
  CONTRACT_REJECTED = "CONTRACT_REJECTED",
  CONTRACT_APPROVED = "CONTRACT_APPROVED",
  CONTRACT_REQUEST = "CONTRACT_REQUEST",
  RFQ_SENT = "RFQ_SEND",
  CANDIDATE_NOMINATED = "TALENT_ADDED",
  CANDIDATE_UPDATED = "TALENT_UPDATED",
  CANDIDATE_OFFERED = "CANDIDATE_OFFERED",
  CANDIDATE_INTERVIEW = "CANDIDATE_INTERVIEW",
  CANDIDATE_HIRED = "CANDIDATE_HIRED",
  REMOVED_FROM_TASK = "REMOVED_FROM_TASK",
  REMOVED_FROM_TASK_SUITABLE = "REMOVED_FROM_TASK_SUITABLE",
  REMOVED_FROM_TASK_INTERVIEW = "REMOVED_FROM_TASK_INTERVIEW",
  // TASK_LOST = "TASK_LOST",
  // TASK_LOST_SUITABLE = "TASK_LOST_SUITABLE",
  // TASK_LOST_INTERVIEW = "TASK_LOST_INTERVIEW",
  TASK_CANCELLED = "TASK_CANCELLED",
  INTERESTED_IN_TASK_REMINDER = "INTERESTED_IN_TASK_REMINDER",
  DEADLINE_PASSED = "DEADLINE_PASSED",
  DEADLINE_REMINDER = "DEADLINE_REMINDER",
  DEADLINE_REMINDER_INTERESTED = "DEADLINE_REMINDER_INTERESTED",
  WON_BID = "WON_BID",
  WITHDRAWN = "WITHDRAWN",
}

// Whats the difference between task lost and removed from task?
