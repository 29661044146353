import { Serializable } from "@/core/api/decorators/serializable_decorator"

@Serializable
export class PagingModel<T> {
  rows: T[]
  numRows: number
  skip: number
  num: number

  constructor(
    rows: T[] = [],
    numRows: number = 0,
    skip: number = 0,
    num: number = 0,
  ) {
    this.rows = rows
    this.numRows = numRows
    this.skip = skip
    this.num = num
  }
}
