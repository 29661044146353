import { FolderIcon } from "@/icons/folder"
import {
  faPenToSquare,
  faTrashCan,
} from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Dropdown, MenuProps, Popover } from "antd"
import { KeyboardEvent, useState } from "react"
import { IFile, IFolder } from "../types"
import { updateFoldername } from "../utils/api"
import { useStorage } from "../utils/context"

const NAME_TOO_LONG = 32

export function Folder({
  folder,
  onClick,
  onRemove,
}: {
  folder: IFolder
  onClick(): void
  onRemove(files: IFile[], folders: IFolder[]): void
}) {
  const [name, setName] = useState<string>(folder.name)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const { isSelecting, selectedContent, setSelectedContent } = useStorage()

  const update = async () => {
    await updateFoldername(folder.uid, name)
    setIsEditing(false)
  }

  const toggleFolder = () => {
    let folders = [...selectedContent.folders]
    const found = folders.find((f) => f.uid === folder.uid)
    if (found) {
      folders = folders.filter((f) => f.uid !== folder.uid)
    } else {
      folders.push(folder)
    }
    setSelectedContent((prev) => ({ ...prev, folders }))
  }

  const isSelected = selectedContent.folders.some((f) => f.uid === folder.uid)

  const onKeyDown = async (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      await update()
    }
  }

  const onMenuClick = (e: { key: string }) => {
    switch (e.key) {
      case "rename": {
        setIsEditing(true)
        break
      }
      case "delete": {
        onRemove([], [folder])
        break
      }
    }
  }

  const items: MenuProps["items"] = [
    {
      label: "Rename folder",
      key: "rename",
      icon: <FontAwesomeIcon icon={faPenToSquare} />,
    },
    {
      label: "Delete folder",
      key: "delete",
      icon: <FontAwesomeIcon icon={faTrashCan} />,
      danger: true,
    },
  ]

  return (
    <Dropdown menu={{ items, onClick: onMenuClick }} trigger={["contextMenu"]}>
      {isEditing ? (
        <div className="group flex h-full w-72 flex-shrink-0 cursor-pointer p-1">
          <div
            className={`flex items-center rounded-lg border-2 border-gray-200 p-4`}
          >
            <FolderIcon className="w-6" />
            <input
              className="ml-2 font-medium"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              onKeyDown={onKeyDown}
              autoFocus
              onFocus={(e) => e.target.select()}
              onBlur={() => setIsEditing(false)}
            />
          </div>
        </div>
      ) : (
        <div
          onClick={isSelecting ? toggleFolder : onClick}
          className="group relative flex h-full w-72 flex-shrink-0 cursor-pointer p-1"
        >
          {isSelecting && (
            <input
              className="absolute right-4 top-4"
              type="checkbox"
              checked={isSelected}
              readOnly
            />
          )}
          <div
            className={`flex h-full w-full items-center rounded-lg border-2 border-gray-200 p-4 group-hover:border-blue-500`}
          >
            <FolderIcon className="w-6 group-hover:text-blue-500" />
            {name?.length > NAME_TOO_LONG ? (
              <Popover content={name}>
                <p className="ml-2 font-medium group-hover:text-blue-500">
                  {name}
                </p>
              </Popover>
            ) : (
              <p className="ml-2 font-medium group-hover:text-blue-500">
                {name}
              </p>
            )}
          </div>
        </div>
      )}
    </Dropdown>
  )
}
