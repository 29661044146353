import { v4 as uuid } from "uuid"

export class RequestModel {
  data: Uint8Array
  iscompressed: boolean
  correlationid: string

  constructor(
    data: Uint8Array,
    iscompressed: boolean,
    correlationid: string = "",
  ) {
    this.data = data
    this.iscompressed = iscompressed
    this.correlationid = correlationid !== "" ? correlationid : uuid()
  }
}
