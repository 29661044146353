import { cn } from "@/utils/helpers"
import { faCalendar } from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DatePicker } from "antd"
import { RangePickerProps } from "antd/es/date-picker"
import dayjs from "dayjs"
import { useEffect, useState } from "react"

type IGenericDateRangePicker = RangePickerProps & {
  label?: string
  readOnly?: boolean
  onChange(date: dayjs.Dayjs[] | null): void
}

export const GenericDateRangePicker = ({
  label,
  value,
  onChange,
  onBlur,
  className,
  readOnly = false,
  ...props
}: IGenericDateRangePicker) => {
  const [dateValue, setDateValue] = useState<[dayjs.Dayjs, dayjs.Dayjs] | null>(
    null,
  ) // Initialize with null

  useEffect(() => {
    if (!!value && Array.isArray(value)) {
      setDateValue(value.map((d) => dayjs(d)) as [dayjs.Dayjs, dayjs.Dayjs])
    }
  }, [value])

  const onChangeHandler = (
    date: [dayjs.Dayjs, dayjs.Dayjs] | null,
    dateString: [string, string],
  ) => {
    setDateValue(date)
    onChange?.(date)
  }

  let format = "DD/MM/YYYY"
  if (typeof props.format === "string") {
    format = props.format
  }

  let placeholder: [string, string] = props?.placeholder ?? [
    "Start date",
    "End date",
  ]

  if (props?.format && typeof props.format === "string") {
    placeholder = [props.format, props.format]
  }

  // Set a specific placeholder when both date values are null
  if (!dateValue || (!dateValue[0] && !dateValue[1])) {
    placeholder = ["Start date", "End date"]
  }

  return (
    <div
      className={cn(
        "border-custom-gray group relative flex min-h-[3rem] w-full flex-col gap-0.5 rounded-xl border bg-white px-4 py-3 ring-inset group-focus:ring-2",
        className,
      )}
    >
      <span
        className={cn(
          "text-custom-gray-dark pointer-events-none bg-inherit text-xs font-light leading-none transition-all",
        )}
      >
        {label}
      </span>

      <div className="h-10">
        {readOnly ? (
          <>
            {dateValue
              ? dateValue.map((d) => d.format(format))
              : "No dates selected!"}
          </>
        ) : (
          <DatePicker.RangePicker
            {...props}
            format={format}
            value={dateValue}
            className={
              "focus:shadow-0 h-10 w-full border-x-0 border-t-0 text-lg"
            }
            onChange={onChangeHandler}
            placeholder={placeholder} // Use the dynamically set placeholder
            suffixIcon={<FontAwesomeIcon icon={faCalendar} color="#ccc" />}
          />
        )}
      </div>
    </div>
  )
}
