import { FolderIcon } from "@/components/icons/folder"
import { ChangeEvent, KeyboardEvent, useState } from "react"
import { toast } from "sonner"
import { PlusIcon } from "."
import { IFolder } from "../types"
import { createFolder } from "../utils/api"

interface IAddFolder {
  currentFolder?: string
  onSave(folder: IFolder): void
}

export function AddFolder({ currentFolder, onSave }: IAddFolder) {
  const [folderName, setFolderName] = useState<string>()
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const handleFolderChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setFolderName(value)
  }

  const handleKeyDown = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      saveFolder()
    } else if (e.key === "Escape") {
      setIsEditing(false)
    }
  }

  const saveFolder = async () => {
    if (!folderName) {
      setIsEditing(false)
      return
    }
    setIsEditing(false)
    setFolderName("")
    const { folder, didexist } = await createFolder(folderName, currentFolder)
    if (folder && !didexist) {
      toast.success("Folder has been created")
      onSave(folder)
    } else {
      toast.error("Folder with that name already exists")
    }
  }

  return (
    <>
      {isEditing ? (
        <div className="group flex h-full w-72 flex-shrink-0 cursor-pointer p-1">
          <div
            className={`flex items-center rounded-lg border-2 border-white p-4`}
          >
            <FolderIcon className="w-6" />
            <input
              className="ml-2 font-medium"
              type="text"
              value={folderName}
              onChange={handleFolderChange}
              onKeyDown={handleKeyDown}
              autoFocus
              onFocus={(e) => e.target.select()}
              onBlur={() => setIsEditing(false)}
            />
          </div>
        </div>
      ) : (
        <div
          onClick={() => setIsEditing(true)}
          className="group flex h-full w-72 flex-shrink-0 cursor-pointer p-1"
        >
          <div className="flex h-full w-full cursor-pointer items-center justify-center rounded-lg border-2 border-dashed border-blue-500 p-4 opacity-50 group-hover:opacity-100">
            <FolderIcon className="w-6 text-blue-500" />
            <PlusIcon className="h-8 w-8 text-blue-500" />
          </div>
        </div>
      )}
    </>
  )
}
