import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { useRecoilValue } from "recoil"
import { meAtom } from "../../atoms/users"
import { useIsMounted } from "../../hooks/useIsMounted"
import { getItem, removeItem, setItem } from "../../utils/browserStorage"

export const SignedOut = ({ children }) => {
  const [isLoggedOut, setIsLoggedOut] = useState(false)
  const isMounted = useIsMounted()
  const user = useRecoilValue(meAtom)
  const router = useRouter()
  const isReturnUrlAlreadySet = !!router.query.returnUrl

  useEffect(() => {
    if (isMounted() && isLoggedOut === true) {
      if (router.asPath.startsWith("/t/")) {
        setItem("ft_redirection", router.asPath)
      }
      if (!isReturnUrlAlreadySet && router.asPath !== "/") {
        router.push({
          pathname: "/",
          query: { returnUrl: router.asPath },
        })
      }
    }
  }, [isLoggedOut])

  useEffect(() => {
    if (isLoggedOut === false && user?.space_user_uid) {
      setTimeout(() => {
        const redirection = getItem("ft_redirection")
        if (redirection && redirection !== router.asPath) {
          router.push(redirection)
          removeItem("ft_redirection")
        }
      }, 0)
    }
  }, [user])

  useEffect(() => {
    const hasCookie = !!getItem("_ft_a_")
    if (hasCookie === false) {
      setIsLoggedOut(true)
    }
  }, [])

  useEffect(() => {
    setIsLoggedOut(!user?.uid)
  }, [user])

  if (isLoggedOut) return children
  return null
}
