import { IAuthUser } from "@/types/auth"
import * as Sentry from "@sentry/nextjs"
import { atom, selector } from "recoil"

export let _me: any = {}

const _meAtom = atom<IAuthUser | undefined>({
  key: "_meAtom",
  default: undefined,
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        Sentry.setUser({ id: newValue?.uid, email: newValue?.email })
      })
    },
  ],
})

export const meAtom = selector({
  key: "me",
  get: ({ get }) => {
    const me = get(_meAtom)
    _me = me
    return me
  },
  set: ({ set }, newValue) => {
    set(_meAtom, newValue)
    _me = newValue
  },
})
