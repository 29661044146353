import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react"
import { IFile, IFolder } from "../types"

type Content = { folders: IFolder[]; files: IFile[] }
interface IStorageContext {
  selectedContent: Content
  setSelectedContent: Dispatch<SetStateAction<Content>>
  isSelecting: boolean
  setIsSelecting: Dispatch<SetStateAction<boolean>>
}

const StorageContext = createContext<IStorageContext>(null)

export const StorageProvider = ({
  children,
}: {
  children: React.ReactElement
}) => {
  const [isSelecting, setIsSelecting] = useState<boolean>(false)
  const [selectedContent, setSelectedContent] = useState<Content>({
    folders: [],
    files: [],
  })

  return (
    <StorageContext.Provider
      value={{
        selectedContent,
        isSelecting,
        setSelectedContent,
        setIsSelecting,
      }}
    >
      {children}
    </StorageContext.Provider>
  )
}

export const useStorage = () => useContext(StorageContext)
