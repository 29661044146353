import { fetcher } from "@/utils/api"
import { queryClient } from "pages/_app"
import { useEffect, useRef, useState } from "react"

export const useDeleteNotification = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleted, setIsDeleted] = useState(false)
  const [canUndo, setCanUndo] = useState(true)
  const timeout = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (isDeleted) {
      // Allow the user to undo for a limited time (8s).
      timeout.current = setTimeout(() => {
        setCanUndo(false)
        // Add a small delay before we invalidate notification queries so
        // the card will have time to complete collapse animation before
        // the item is removed from the dataset.
        setTimeout(() => {
          queryClient.invalidateQueries({
            queryKey: ["notifications"],
          })
        }, 1000)
      }, 8000)
    } else {
      clearTimeout(timeout.current)
    }
  }, [isDeleted])

  const deleteNotification = async (uid: string) => {
    setIsLoading(true)
    try {
      await fetcher(`/notifications/v2/${uid}`, {
        method: "DELETE",
      })
      setIsLoading(false)
      setIsDeleted(true)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const undoDeleteNotification = async (uid: string) => {
    setIsLoading(true)
    clearTimeout(timeout.current)
    try {
      await fetcher(`/notifications/v2/${uid}/undo-delete`, {
        method: "PUT",
      })
      setIsLoading(false)
      setIsDeleted(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  return {
    isLoading,
    isDeleted,
    canUndo,
    deleteNotification,
    undoDeleteNotification,
  }
}
