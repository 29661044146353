import { IAppConfig } from "@/features/settings/spaces/components/ModalCreateAppConfig"
import { fetcher } from "../index"

export const updateLogo = async (file) => {
  return await fetcher(`/organisations/logo`, {
    method: "PUT",
    body: file,
  })
}

export const updateOrganisation = async (uid, space) => {
  return await fetcher(`/organisations/${uid}`, {
    method: "PUT",
    body: JSON.stringify(space),
  })
}

export const getSpaceConfig = async (space: string) => {
  return await fetcher(`/spaces/${space}/config`)
}

export const updateSpaceConfig = async (
  config: Record<string, any>,
  space: string,
) => {
  return await fetcher(`/spaces/${space}/config`, {
    method: "POST",
    body: JSON.stringify({ config }),
  })
}

export const getAppConfigTemplates = async (space: string) => {
  return await fetcher(`/spaces/${space}/app_config_templates`)
}

export const createAppConfigTemplate = async (
  config: IAppConfig,
  space: string,
) => {
  return await fetcher(`/spaces/${space}/app_config_templates`, {
    method: "POST",
    body: JSON.stringify({ config }),
  })
}

export const updateAppConfigTemplate = async (
  config: IAppConfig,
  space: string,
) => {
  return await fetcher(`/spaces/${space}/app_config_templates`, {
    method: "PUT",
    body: JSON.stringify({ config }),
  })
}

export const getSpaceByUid = async (uid: string) => {
  return await fetcher(`/spaces/${uid}`)
}

export const getThemeByHost = async (host?: string) => {
  return await fetcher(`/public/theme` + (host ? `/${host}` : ""))
}
