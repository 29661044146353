import { StatusCode } from "@/core/api/enums/status_code"
import { ResponseModel } from "@/core/api/models/response_model"
import { ResponseSerializer } from "@/core/api/response_serializer"
import { RequestModel } from "@/core/api/models/request_model"
import { RequestSerializer } from "../request_serializer"

export class APIFactory {
  public static createResponse<T>(
    model: T,
    code: number = StatusCode.Ok,
    message: string = "",
    useCompression: boolean = true,
    correlationId: string = "",
  ): ResponseModel {
    let data: Uint8Array = new Uint8Array(0)
    if (model !== null) {
      data = ResponseSerializer.serialize<T>(model, useCompression)
    } else {
      useCompression = false
    }

    const response = new ResponseModel(
      data,
      code,
      message,
      useCompression,
      correlationId,
    )

    return response
  }

  public static createEmptyResponse<T>(
    code: number = StatusCode.Ok,
    message: string = "",
    useCompression: boolean = true,
    correlationId: string = "",
  ): ResponseModel {
    const response = new ResponseModel(
      new Uint8Array(0),
      code,
      message,
      useCompression,
      correlationId,
    )

    return response
  }

  public static createRequest<T>(
    model: T,
    useCompression: boolean = true,
    correlationId: string = "",
  ): RequestModel {
    let data: Uint8Array = new Uint8Array(0)
    if (model !== null) {
      data = RequestSerializer.serialize<T>(model, useCompression)
    } else {
      useCompression = false
    }

    const response = new RequestModel(data, useCompression, correlationId)

    return response
  }
}
