import { useRouter } from "next/router"
import { useEffect, useRef, useState } from "react"
import RedirectPage from "../../pages/r/[code]"

export const REDIRECTION_HOSTS = ["flx.sh", "r.flextribe.io"]

export const RedirectProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isOrigin, setIsOrigin] = useState(false)

  const router = useRouter()

  const isRedirecting = useRef(false)

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (
        REDIRECTION_HOSTS.includes(window.location.hostname) &&
        !isRedirecting.current
      ) {
        isRedirecting.current = true

        let code = ""
        // We need to include the trailing "/", otherwise redirects won't work for codes starting with "r"
        if (!router.asPath.startsWith("/r/")) {
          if (code === "") {
            const windowLocation = { ...window.location }
            code = windowLocation.pathname.replace("/", "")
          }

          router.push(
            {
              pathname: "/",
              query: {
                code: code,
              },
            },
            "/" + code,
            { shallow: true },
          )
        }

        setIsOrigin(true)
      }

      setIsLoading(false)
    }
  }, [])

  if (isLoading) return null

  if (isOrigin) return <RedirectPage />

  return <>{children}</>
}

export default RedirectProvider
