import Pusher, { Channel, PresenceChannel } from "pusher-js"
import React, { useContext, useEffect } from "react"
import { useSetRecoilState } from "recoil"
import { toast } from "sonner"
import { SOCKET_STATES, socketAtom } from "../atoms/socket"
import { meAtom } from "../atoms/users"
import { fetcher } from "../utils/api"
import { getItem } from "../utils/browserStorage"
import { onlineAtom } from "@/atoms/online"
import { IUser } from "@/features/people/types"

export const PusherContext = React.createContext<{
  socket
  subscribe
  unsubscribe
  bind
  bindClientEvent
  triggerClientEvent
  getMemberInfo
  unbind
  unbindAll
}>(undefined)

const API_URL = process.env.NEXT_PUBLIC_API_URL

const _socket = (() => {
  if (typeof window === "undefined") {
    return null
  }

  return new Pusher("bf738691dd2d11d24750", {
    cluster: "eu",
    userAuthentication: {
      endpoint: `${API_URL}/socket/auth/user`,
      transport: "ajax",
      headers: {
        Authorization: `Bearer ${getItem("_ft_a_")}`,
      },
    },
    channelAuthorization: {
      endpoint: `${API_URL}/socket/auth/channel`,
      transport: "ajax",
      headers: {
        Authorization: `Bearer ${getItem("_ft_a_")}`,
      },
    },
  })
})()

export default function PusherProvider({ children }) {
  const setSocketAtom = useSetRecoilState(socketAtom)
  const setOnlineAtom = useSetRecoilState(onlineAtom)

  const callOnlineState = async () => {
    try {
      await fetcher("/me/online", { method: "POST" })
      setOnlineAtom(true)
    } catch (error) {
      if (error !== "Unauthorized") {
        setOnlineAtom(false)
        toast.error(
          "We are having trouble connecting to our servers. Please check your internet connection and try again.",
        )
      }
    }
  }

  const callOfflineState = async () => {
    navigator.sendBeacon(
      API_URL + "/me/offline?type=beacon&authToken=" + getItem("_ft_a_"),
    )
  }

  const connect = () => {
    console.warn("Connecting to socket")
    _socket.connect()
    _socket.signin()

    _socket.connection.bind("connected", () => {
      console.warn("Connected to socket")
      setSocketAtom((prev) => ({ ...prev, state: SOCKET_STATES.CONNECTED }))
    })

    _socket.connection.bind("disconnected", () => {
      console.warn("Disconnected to socket")
      setSocketAtom((prev) => ({ ...prev, state: SOCKET_STATES.DISCONNED }))
    })

    _socket.bind_global((event, data) => {
      if (event === "maintenance") {
        toast(<>{data}</>, {
          position: "top-center",
          duration: 400000,
        })
      }
    })

    _socket.user.bind_global((event, data) => {
      console.warn("User event", event, data)
    })

    return _socket
  }

  useEffect(() => {
    connect()

    if (window) {
      callOnlineState()

      setInterval(() => {
        callOnlineState()
      }, 1000 * 30)

      window.addEventListener("beforeunload", callOfflineState)
      window.addEventListener("online", callOnlineState)
      window.addEventListener("offline", callOfflineState)
      window.addEventListener("pagehide", callOfflineState)
    }

    return () => {
      _socket.disconnect()
    }
  }, [])

  const subscribe = async ({
    channel,
    name,
  }: {
    channel: string
    name?: string
  }) => {
    if (!_socket) return

    if (_socket.channel(channel)?.subscribed) return _socket.channel(channel)

    const _channel = await _socket?.subscribe(channel)
    _channel?.bind("pusher:subscription_succeeded", () => {
      setSocketAtom((prev) => {
        const prevSubscribedTo = prev.subscribed_to || []
        if (!prevSubscribedTo.includes(name)) {
          return { ...prev, subscribed_to: [...prevSubscribedTo, name] }
        }
        return { ...prev, subscribed_to: prevSubscribedTo }
      })
    })
    _channel?.bind("pusher:subscription_error", (error) => {
      console.error(error)
    })

    return await new Promise((resolve, reject) => {
      let attempts = 0

      const timer = setInterval(() => {
        attempts++
        if (_channel?.subscribed) {
          clearInterval(timer)
          resolve(_channel)
        } else if (attempts > 200) {
          clearInterval(timer)
          console.warn("Timed out")
          reject("Timed out")
        }
      }, 50)
    })
  }

  const unsubscribe = async ({
    channel,
    name,
  }: {
    channel: string
    name?: string
  }) => {
    if (!_socket) return

    _socket.channel(channel)?.unsubscribe()

    return new Promise((resolve, reject) => {
      let attempts = 0

      const timer = setInterval(() => {
        attempts++
        if (channel) {
          const getChannel = _socket?.channel(channel)
          if (getChannel?.subscribed === false) {
            setSocketAtom((prev) => {
              const prevSubscribedTo = [...(prev.subscribed_to || [])]
              const indexOfName = prevSubscribedTo.indexOf(name)

              if (indexOfName > -1) {
                prevSubscribedTo.splice(indexOfName, 1)
              }

              return { ...prev, subscribed_to: prevSubscribedTo }
            })
            clearInterval(timer)
            resolve(getChannel)
          }
        }

        if (attempts > 200) {
          clearInterval(timer)
          reject("Timed out")
        }
      }, 50)
    })
  }

  const getChannel = async (channel: string): Promise<Channel> => {
    return _socket.subscribe(channel)
  }

  const unbind = async ({
    event,
    channel,
    callback,
  }: {
    event: string
    channel?: string
    callback?: (data?: any) => void
  }) => {
    if (!_socket) return

    if (channel) {
      const _channel = await getChannel(channel)
      _channel?.unbind(event)
    } else {
      _socket.unbind(event)
    }
  }

  const unbindAll = async (channel: string) => {
    if (!_socket) return

    if (channel) {
      const _channel = await getChannel(channel)
      _channel?.unbind()
    }
  }

  const bind = async ({
    event,
    channel,
    callback,
  }: {
    event: string
    channel?: string
    callback?: (data?: any) => void
  }) => {
    if (!_socket) return
    if (channel) {
      const _channel = await _socket.subscribe(channel)
      await unbind({ event, channel })
      _channel?.bind(event, callback)
    } else {
      await unbind({ event })
      _socket.bind(event, callback)
    }
  }

  const bindClientEvent = async ({
    event,
    channel,
    callback,
  }: {
    event: string
    channel?: string
    callback?: (data?: any) => void
  }) => {
    if (!_socket) return

    if (channel) {
      await bind({ event: "client-" + event, channel, callback })
    } else {
      await bind({ event: "client-" + event, callback })
    }
  }

  const triggerClientEvent = async ({
    channel,
    event,
    data,
  }: {
    channel: string
    event: string
    data: any
  }) => {
    if (!_socket) return

    _socket?.subscribe(channel).trigger("client-" + event, data)
  }

  const getMemberInfo = async ({
    channel,
    user_id,
  }: {
    channel: string
    user_id: string
  }): Promise<IUser | null> => {
    if (!_socket) return
    const _channel = _socket
      ? ((await getChannel(channel)) as PresenceChannel)
      : null
    const member = _channel?.members.get(user_id)
    const memberInfo = member && member.info ? (member.info as IUser) : null

    return memberInfo
  }

  return (
    <PusherContext.Provider
      value={{
        socket: _socket,
        subscribe,
        bind,
        bindClientEvent,
        triggerClientEvent,
        unsubscribe,
        getMemberInfo,
        unbind,
        unbindAll,
      }}
    >
      {children}
    </PusherContext.Provider>
  )
}

export const usePusher = () => useContext(PusherContext)
