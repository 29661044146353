import { useTranslation } from "next-i18next"
import { NotificationListView } from "../components/NotificationListView"
import { useNotifications } from "../hooks/useNotifications"
import { NotificationCategory } from "../types/notificationCategory"
import { NotificationScope } from "../types/notificationScope"

type TNotificationListContainerProps = {
  scope: NotificationScope
  category: NotificationCategory
}

export const NotificationListContainer = ({
  scope,
  category,
}: TNotificationListContainerProps) => {
  const { t } = useTranslation("notifications")
  const { notifications, isFetching, isFetched, fetchNextPage } =
    useNotifications(scope, category)

  if (isFetched && notifications.length === 0) {
    return (
      <div className="h-full bg-white pt-10 text-center text-gray-400">
        {t("no_notifications")}
      </div>
    )
  }

  return (
    <NotificationListView
      category={category}
      notifications={notifications}
      isFetching={isFetching}
      onEndReached={fetchNextPage}
    />
  )
}
