import NextErrorComponent from "next/error"
import { signOut } from "../utils/api/authentication"

const Error500Page = () => {
  return (
    <>
      <div
        className="absolute right-0 top-0 mr-4 mt-4 cursor-pointer rounded-md bg-blue-500 px-4 py-2 text-white"
        onClick={() => {
          signOut()
        }}
      >
        Log out
      </div>
      <NextErrorComponent statusCode={500} />
    </>
  )
}

export default Error500Page
