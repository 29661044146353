import dayjs from "dayjs"
import { useTranslation } from "next-i18next"

export const useTimeFromNowShort = (date: string) => {
  const { t } = useTranslation("time")
  const now = dayjs()

  if (now.diff(date, "minutes") < 2) {
    return t("short.now")
  } else if (now.diff(date, "minutes") < 60) {
    return t("short.minutes", { minutes: now.diff(date, "minutes") })
  } else if (now.diff(date, "hours") < 24) {
    return t("short.hours", { hours: now.diff(date, "hours") })
  } else if (now.diff(date, "days") < 7) {
    return t("short.days", { days: now.diff(date, "days") })
  } else return t("short.weeks", { weeks: now.diff(date, "weeks") })
}
