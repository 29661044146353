import { cn } from "@/utils/helpers"
import React, { InputHTMLAttributes, useEffect, useState } from "react"

interface INumberInput
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  onChange?: (value: number) => void
  label?: string
  readOnly?: boolean
  min?: number
  max?: number
  prefix?: string
  suffix?: string
  error?: string
}

export const NumberInput = React.forwardRef(
  (
    {
      onChange,
      value,
      prefix,
      suffix,
      label,
      className,
      min,
      max,
      ...props
    }: INumberInput,
    ref: React.RefObject<HTMLLabelElement>,
  ) => {
    const [inputValue, setInputValue] = useState<number>()
    const [error, setError] = useState<string>()

    useEffect(() => {
      setInputValue(value as number)
    }, [value])

    const handleChange = (e) => {
      let result = e.target.value.replace(/\D\./g, "")

      if (!isNaN(result)) {
        if (result < min || result > max) {
          setError(`Value must be between ${min} and ${max}`)
          setInputValue(result)
          return
        }
      } else {
        result = undefined
      }

      setError(undefined)

      setInputValue(result)
      onChange?.(Number(result))
    }

    return (
      <div className="w-full">
        {props.readOnly ? (
          <p className={className}>{value}</p>
        ) : (
          <div
            className={cn(
              "border-custom-gray group relative flex min-h-[3rem] w-full flex-col gap-0.5 rounded-xl border bg-white px-4 py-3 ring-inset group-focus:ring-2",
              error || (props.error && "border-red-500"),
            )}
          >
            <span
              className={cn(
                "text-custom-gray-dark pointer-events-none bg-inherit text-xs font-light leading-none transition-all",
              )}
            >
              {label}
            </span>
            <input
              ref={ref as any}
              {...props}
              type="text"
              onChange={handleChange}
              value={inputValue !== 0 && !inputValue ? "" : inputValue}
              className={cn(
                "w-full select-none border-none bg-white text-sm text-gray-500 placeholder-gray-500 outline-none focus:bg-white",
                error && "border-red-500",
                className,
              )}
            />

            {!!suffix && (
              <p className="absolute right-0 top-1/2 -translate-y-1/2">
                {suffix}
              </p>
            )}
            {error ||
              (props.error && (
                <p className="mr-1 mt-1 text-right text-xs text-red-500">
                  {error || props.error}
                </p>
              ))}
          </div>
        )}
      </div>
    )
  },
)

NumberInput.displayName = "NumberInput"
