import { getThemeByHost } from "@/utils/api/spaces"
import { useLocalStorage } from "hooks/useLocalStorage"
import { isEmpty } from "lodash"
import { createContext, useContext, useEffect, useState } from "react"

export const ThemeContext = createContext(null)

type TColors = {
  primary?: string
  on_primary?: string
  primary_container?: string
  on_primary_container?: string
  secondary?: string
  on_secondary?: string
  secondary_container?: string
  on_secondary_container?: string
  surface?: string
  on_surface?: string
  on_surface_variant?: string
  inverse_surface?: string
  inverse_on_surface?: string
  inverse_primary?: string
}

type TTheme = TColors & {
  primary?: string
  secondary?: string
  black?: string
  white?: string
  blue?: {
    700?: string
    600?: string
    500?: string
    100?: string
  }
  red?: string
  authimg?: string
  authlogo?: string
  login_screen?: {
    color?: string
    title?: string
    statement?: string
  }
  logosymbol?: string
  login_headline?: string
  login_description?: string
  login_color?: string
  intro?: string
  refereshWithHost?: (host: string) => void
}

export const useTheme = () => useContext<TTheme>(ThemeContext)

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useLocalStorage<TTheme>("_theme_", {})
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    fetchTheme()
  }, [])

  const refereshWithHost = async (host: string) => {
    try {
      let theme = await getThemeByHost(host)
      setTheme(theme)
    } catch (error) {}
  }

  const fetchTheme = async () => {
    if (typeof window === "undefined") return
    try {
      let theme = await getThemeByHost()
      setTheme(theme)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  if (loading && isEmpty(theme)) return null

  return (
    <ThemeContext.Provider value={{ ...theme, refereshWithHost }}>
      <style jsx global>
        {`
          :root {
            --color-black: ${theme?.black};
            --color-white: ${theme?.white};
            --color-blue-700: ${theme?.blue?.["700"] || ""};
            --color-blue-600: ${theme?.blue?.["600"] || ""};
            --color-blue-500: ${theme?.blue?.["500"] || ""};
            --color-blue-100: ${theme?.blue?.["100"] || ""};
            --color-red-500: ${theme?.red?.["500"] || ""};
            --image-auth-img: ${theme?.authimg};
            --image-auth-logo: ${theme?.authlogo};
            --image-logo-symbol: ${theme?.logosymbol};
            --color-intro: ${theme?.intro};
          }
        `}
      </style>
      {children}
    </ThemeContext.Provider>
  )
}
