import { cloneDeep, debounce } from "lodash"
import { useState } from "react"
import { useRecoilState } from "recoil"
import { toast } from "sonner"
import { selectedSpaceAtom } from "../../atoms/spaces"
import Modal from "../../components/modal/Modal"
import { ColorPickerInput } from "../../shared/components/inputs/ColorPicker"
import { updateSpaceConfig } from "../../utils/api/spaces"
import { createNestedObject, deepMerge } from "../../utils/helpers"
import { Icon } from "../icons/components/IconLoader"

export const ThemingPageButton = () => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <div
        onClick={() => setOpen(true)}
        className="absolute right-0 top-0 z-10 flex cursor-pointer rounded-bl-full bg-blue-500 pb-1 pl-1 text-white opacity-0 hover:opacity-100"
      >
        <Icon icon="fasCog" size={"2xs"} />
      </div>
      <Modal open={open} close={() => setOpen(false)}>
        <ThemingPage />
      </Modal>
    </>
  )
}

export const ThemingPage = () => {
  const [selectedSpace, setSelectedSpace] = useRecoilState(selectedSpaceAtom)
  const updateConfig = async (key, value, silent: boolean = false) => {
    const newConfig = {
      ...selectedSpace.config,
      theme: deepMerge(
        cloneDeep(selectedSpace.config?.theme),
        createNestedObject(key, value),
      ),
    }

    setSelectedSpace((prev) => ({
      ...prev,
      config: newConfig,
    }))

    if (silent) return

    toast.promise(updateSpaceConfig(newConfig, selectedSpace.uid), {
      success: "Settings updated",
      loading: "Updating settings",
      error: "Error updating settings",
    })
  }

  const updateConfigDebounced = debounce(updateConfig, 500)

  const colorCustomizers = [
    {
      key: "primary",
      label: "Primary",
      value: selectedSpace.config?.theme?.primary,
    },
    {
      key: "secondary",
      label: "Secondary",
      value: selectedSpace.config?.theme?.secondary,
    },
    {
      key: "black",
      label: "Black",
      value: selectedSpace.config?.theme?.black,
    },
    {
      key: "white",
      label: "White",
      value: selectedSpace.config?.theme?.white,
    },
    {
      key: "blue.700",
      label: "Blue 700",
      value: selectedSpace.config?.theme?.blue?.["700"],
    },
    {
      key: "blue.600",
      label: "Blue 600",
      value: selectedSpace.config?.theme?.blue?.["600"],
    },
    {
      key: "blue.500",
      label: "Blue 500",
      value: selectedSpace.config?.theme?.blue?.["500"],
    },
    {
      key: "blue.100",
      label: "Blue 100",
      value: selectedSpace.config?.theme?.blue?.["100"],
    },
    {
      key: "red.500",
      label: "Red 500",
      value: selectedSpace.config?.theme?.red?.["500"],
    },
  ]
  return (
    <div className="flex flex-col gap-2">
      {colorCustomizers.map((item) => (
        <div key={item.key} className="flex items-center gap-2">
          <p className="w-40 whitespace-nowrap text-sm font-medium">
            {item.label}:{" "}
          </p>
          <ColorPickerInput
            color={item.value}
            format="hex"
            onSelect={(e) => {
              updateConfigDebounced(item.key, e)
            }}
          />
        </div>
      ))}
    </div>
  )
}
