export const useButtonVariant = (variant: string) => {
  const variants = {
    default:
      "bg-white border border-gray-300 hover:bg-gray-50 focus:outline-blue-500",
    primary: "bg-blue-500 hover:opacity-100 text-white transition-all",
    destructive: "bg-red-400 hover:opacity-100 text-white transition-all",
    confirm: "bg-green-400 hover:opacity-100 text-white transition-all",
    ghost:
      "bg-transparent hover:bg-gray-200 focus:outline-blue-500 transition-all",
    orange: "bg-orange-100 text-custom-orange transition-all",
    green: "bg-green-100 hover:opacity-100 text-custom-green transition-all",
  }

  if (!variants[variant]) {
    return null
  }

  return variants[variant]
}

export const movingButtonLoaderVariant = (variant: string) => {
  const variants = {
    default: "bg-black",
    primary: "bg-white",
    destructive: "bg-white",
    confirm: "bg-white",
    ghost: "bg-transparent",
    orange: "bg-custom-orange",
    green: "bg-custom-green",
  }

  if (!variants[variant]) {
    return null
  }

  return variants[variant]
}

export const buttonRadialVariant = (variant: string) => {
  const variants = {
    default: "bg-[radial-gradient(var(--custom-gray)_40%,transparent_60%)]",
    primary: "bg-[radial-gradient(var(--blue-500)_40%,transparent_60%)]",
    destructive: "bg-[radial-gradient(var(--red-300)_40%,transparent_60%)]",
    confirm: "bg-[radial-gradient(var(--green-400)_40%,transparent_60%)]",
    ghost: "bg-[radial-gradient(var(--custom-gray)_40%,transparent_60%)]",
    orange: "bg-[radial-gradient(var(--orange-200)_40%,transparent_60%)]",
    green: "bg-[radial-gradient(var(--green-200)_40%,transparent_60%)]",
  }

  if (!variants[variant]) {
    return null
  }

  return variants[variant]
}
