import { Serializable } from "@/core/api/decorators/serializable_decorator"

@Serializable
export class TaskTemplateListView {
  templateId: string = ""
  templateName: string = ""
  ownerId: string = ""
  ownerName: string = ""
  numTasks: number = 0
  numInterested: number = 0

  constructor(
    data: {
      templateid: string
      templatename: string
      ownerid: string
      ownername: string
      numtasks: number
      numinterested: number
    } | null = null,
  ) {
    if (data) {
      this.templateId = data.templateid
      this.templateName = data.templatename
      this.ownerId = data.ownerid
      this.ownerName = data.ownername
      this.numTasks = data.numtasks
      this.numInterested = data.numinterested
    }
  }
  /*
  static fromJSON(json: any): TaskTemplateListView {
    return new TaskTemplateListView(json)
  }
  */
}
