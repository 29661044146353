import { IField } from "@/features/fields/types"
import { IAccess } from "@/types/auth"
import { fetcher } from "@/utils/api"
import { IExtendedTemplate, IGroup, ITab, ITemplate } from "../types"

export const TASK_TEMPLATE_APP = "52e7c717-d7fe-48fb-8916-61b8fea4ecbb"

export const getTaskTemplates = async (
  params?: Partial<Record<keyof ITemplate, any>> & { space?: string },
): Promise<ITemplate[]> => {
  const queryString = new URLSearchParams(params).toString()
  return await fetcher(
    `/apps/${TASK_TEMPLATE_APP}/data/templates?${queryString}`,
  )
}

export const getTaskTemplate = async (
  uid: string,
): Promise<IExtendedTemplate> => {
  return await fetcher(`/apps/${TASK_TEMPLATE_APP}/data/templates/${uid}`)
}

export const getTaskTemplateByPublicKey = async (
  publicKey: string,
): Promise<IExtendedTemplate> => {
  return await fetcher(`/public/${publicKey}/template`)
}

export const getTaskQuickActions = async (): Promise<ITemplate[]> => {
  return await fetcher(`/apps/${TASK_TEMPLATE_APP}/data/templates/quickactions`)
}

export const getChildTaskTemplates = async (
  parentUid: string,
): Promise<ITemplate[]> => {
  return await fetcher(
    `/apps/${TASK_TEMPLATE_APP}/data/templates/${parentUid}/children`,
  )
}

export const createTaskTemplate = async (body: {
  name: string
  fields: IField[]
  tabs: ITab[]
  groups: IGroup[]
  relations: ITemplate["relations"]
  users: { user: string; access: IAccess }[]
  is_quickaction?: boolean
  copy_from_parent?: boolean
  is_minibid?: boolean
  [key: string]: any
}): Promise<ITemplate> => {
  return await fetcher(`/apps/${TASK_TEMPLATE_APP}/data/templates`, {
    method: "POST",
    body: JSON.stringify(body),
  })
}

export const updateTaskTemplate = async (
  uid: string,
  body: {
    name: string
    fields: IField[]
    tabs: ITab[]
    groups: IGroup[]
    relations: ITemplate["relations"]
    users: { user: string; access: IAccess }[]
    is_quickaction?: boolean
    copy_from_parent?: boolean
    is_minibid?: boolean
    [key: string]: any
  },
): Promise<ITemplate> => {
  return await fetcher(`/apps/${TASK_TEMPLATE_APP}/data/templates/${uid}`, {
    method: "PUT",
    body: JSON.stringify(body),
  })
}

export const duplicateTaskTemplate = async (
  uid: string,
): Promise<ITemplate> => {
  return await fetcher(`/apps/${TASK_TEMPLATE_APP}/data/templates/${uid}`, {
    method: "POST",
  })
}

export const deleteTaskTemplate = async (uid: string) => {
  return await fetcher(`/apps/${TASK_TEMPLATE_APP}/data/templates/${uid}`, {
    method: "DELETE",
  })
}
