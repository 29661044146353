import { ITableBody } from "@/types/tables"
import { fetcher } from ".."

const APP_UID = "4ba3ba8b-e2d5-40b3-8504-c580e4fc4434"

export const getTablesByLabel = async (label?: string) => {
  const qs = label ? `?label=${label}` : ""
  return await fetcher(`/tables${qs ? qs : ""}`)
}

export const createTable = async (table: ITableBody) => {
  return await fetcher(`/tables`, {
    method: "POST",
    body: JSON.stringify(table),
  })
}

// TO BE DELETED

export const getConfig = async (uid: string) => {
  return await fetcher(`/apps/${APP_UID}/data/reference/${uid}`)
}

export const saveConfig = async (uid: string, columns: string[]) => {
  return await fetcher(`/apps/${APP_UID}/data/reference/${uid}`, {
    method: "POST",
    body: JSON.stringify({ columns }),
  })
}
