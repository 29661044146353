import { cn } from "@/utils/helpers"
import { Ring } from "@uiball/loaders"
import { HTMLAttributes } from "react"
import { useButtonVariant } from "./useButtonVariant"

type IntrinsicProps = {
  [K in keyof JSX.IntrinsicElements]: JSX.IntrinsicElements[K] & { as?: K }
}[keyof JSX.IntrinsicElements]

type IBasicButton = IntrinsicProps &
  (HTMLAttributes<HTMLOrSVGElement> & {
    variant?:
      | "default"
      | "primary"
      | "destructive"
      | "confirm"
      | "ghost"
      | "orange"
      | "green"
    loading?:
      | boolean
      | {
          loading: boolean
          color?: string
          size?: number
        }
    disabled?: boolean
  })

export const BasicButton = ({
  children,
  as = "button",
  variant = "default",
  loading,
  className,
  ...props
}: IBasicButton) => {
  const Wrapper: any = as
  const isObject = typeof loading === "object"
  const isLoading = (!isObject && loading) || (isObject && loading?.loading)
  let color = isObject ? loading?.color : "var(--color-blue-600)"
  let size = isObject ? loading?.size : 14
  const variantClass = useButtonVariant(variant)

  return (
    <Wrapper
      {...props}
      className={cn(
        "hover:scale-102 w-fit cursor-pointer rounded-md px-4 py-2 disabled:cursor-default disabled:opacity-50",
        variantClass,
        className,
      )}
    >
      {isLoading ? (
        <div className="grid place-content-center">
          <Ring color={color} size={size} />
        </div>
      ) : typeof children === "string" ? (
        <p className={`text-xs font-medium`}>{children}</p>
      ) : (
        children
      )}
    </Wrapper>
  )
}
