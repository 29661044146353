import {
  faBell,
  faClose,
} from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "core/components/Button"
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "core/components/Sheet"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "core/components/Tabs"
import { useTranslation } from "next-i18next"
import { useState } from "react"
import { useUnreadNotificationsCount } from "../hooks/useUnreadNotificationsCount"
import { NotificationCategory } from "../types/notificationCategory"
import { NotificationScope } from "../types/notificationScope"
import { NotificationListContainer } from "./NotificationListContainer"

export const NotificationSheet = () => {
  const { t } = useTranslation("notifications")
  const [scope, setScope] = useState(NotificationScope.PERSONAL)
  const {
    total: allCount,
    candidates: candidatesCount,
    tasks: tasksCount,
  } = useUnreadNotificationsCount(scope)

  const tabs = [
    { key: NotificationCategory.ALL, label: t("tabs.all"), unread: allCount },
    {
      key: NotificationCategory.CANDIDATES,
      label: t("tabs.candidates"),
      unread: candidatesCount,
    },
    {
      key: NotificationCategory.TASKS,
      label: t("tabs.tasks"),
      unread: tasksCount,
    },
  ]

  return (
    <Sheet>
      <SheetTrigger asChild>
        <div className="relative">
          <FontAwesomeIcon
            className={`cursor-pointer text-gray-400 ${allCount > 0 ? "animate-wiggle-pulse-blue" : ""}`}
            icon={faBell}
            size="lg"
          />
          {allCount > 0 && (
            <div className="absolute right-0 top-0 h-2 w-2 rounded-full bg-red-400" />
          )}
        </div>
      </SheetTrigger>
      <SheetContent
        aria-describedby={undefined}
        className="bg-surface flex w-full !max-w-none flex-col gap-0 border-0 p-0 sm:w-[30rem] sm:rounded-tl-xl"
      >
        <SheetHeader className="flex flex-row justify-between pb-8 pl-4 pr-1 pt-6">
          <SheetTitle>{t("title")}</SheetTitle>
          <div className="!m-0 flex items-center space-x-1">
            <SheetClose asChild>
              <Button variant="text" size="icon">
                <FontAwesomeIcon
                  className="cursor-pointer"
                  icon={faClose}
                  size="xl"
                />
              </Button>
            </SheetClose>
          </div>
        </SheetHeader>
        <Tabs
          defaultValue={tabs[0].key}
          className="flex flex-1 flex-col overflow-hidden"
        >
          <TabsList>
            {tabs.map((tab) => (
              <TabsTrigger
                key={tab.key}
                className="[&>div]:data-[state=active]:bg-primary/10 flex flex-1 justify-center px-0"
                value={tab.key}
              >
                {tab.label}
                {tab.unread > 0 && (
                  <div className="ml-1.5 rounded-full bg-gray-200 px-1.5 text-sm">
                    {tab.unread}
                  </div>
                )}
              </TabsTrigger>
            ))}
          </TabsList>
          {tabs.map((tab) => (
            <TabsContent
              className="h-full"
              key={tab.key}
              value={tab.key}
              forceMount
            >
              <NotificationListContainer scope={scope} category={tab.key} />
            </TabsContent>
          ))}
        </Tabs>
      </SheetContent>
    </Sheet>
  )
}
