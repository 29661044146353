import { cn } from "@/utils/helpers"
import { InputHTMLAttributes, TextareaHTMLAttributes, forwardRef } from "react"
interface ITextInputProps {
  label?: string
  locked?: boolean
  textarea?: boolean
  error?: string
  labelClassName?: string
}

type ITextInput = ITextInputProps &
  (
    | InputHTMLAttributes<HTMLInputElement>
    | TextareaHTMLAttributes<HTMLTextAreaElement>
  )

export const TextInput = forwardRef(
  ({ readOnly, locked, label, error, ...props }: ITextInput, ref: any) => {
    props.value = props.value || ""
    return (
      <div className="w-full">
        {readOnly || locked ? (
          <p className={props.className}>{props.value}</p>
        ) : (
          <div
            className={cn(
              "border-custom-gray group relative flex min-h-[3rem] w-full flex-col gap-0.5 rounded-xl border bg-white px-4 py-3 ring-inset group-focus:ring-2",
              error && "border-red-500",
            )}
          >
            <span
              className={cn(
                "text-custom-gray-dark pointer-events-none bg-inherit text-xs font-light leading-none transition-all",
                props.labelClassName,
              )}
            >
              {label}
            </span>
            {props?.textarea ? (
              <textarea
                className={cn(
                  "disabled:text-custom-gray-dark w-full select-none border-none bg-white text-sm text-gray-500 placeholder-gray-500 outline-none focus:bg-white",
                  props.className,
                )}
                {...(props as InputHTMLAttributes<HTMLTextAreaElement>)}
                ref={ref}
              />
            ) : (
              <input
                {...(props as InputHTMLAttributes<HTMLInputElement>)}
                className={cn(
                  "text-custom-gray-dark w-full select-none bg-white text-sm placeholder-gray-500 outline-none focus:bg-white",
                )}
                ref={ref}
              />
            )}

            {error && (
              <p className="mr-1 mt-1 text-right text-xs text-red-500">
                {error}
              </p>
            )}
          </div>
        )}
      </div>
    )
  },
)

TextInput.displayName = "TextInput"
